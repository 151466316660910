/********************/
/***** COUPON *******/
/********************/ 

body {
    .box-coupon{
        .count-container{
            background: var(--semi-dark-7);
            color: var(--light);
        }
        .qbet-box{
            background: var(--tertiary-box);
            .qbet-header{
                .row_header2{
                    .header_sx2{
                        @include heading-small;
                        .question{
                            &:hover{
                                i{
                                    color: var(--primary)
                                }
                            }
                        }
                    }
                    .header_dx2{
                        span.cod-ev{
                            color: var(--primary-text);
                        }
                        input#qbettxt{
                            border-color:var(--semi-light);
                            background: var(--semi-light);
                            color: var(--primary-text);
                        }

                    }
                }
                .div-select{
                    span#spneve{
                        color: var(--primary-text);
                    }
                    select{
                        border-color: var(--semi-light);
                        background-color: var(--semi-light);
                        color: var(--primary-text);
                        optgroup{
                            background: var(--secondary);
                            color: var(--primary-text); 
                            option{
                                background-color: var(--tertiary-box);                                
                                color: var(--primary-text); 
                                &:hover{
                                    background-color: var(--semi-light-12)!important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .header_void{
            .voidint{
                .header_sx{
                    @include heading-small;
                }
            }
            .row_header_cx{
                background: var(--tertiary-box);
                .p_void_cx{
                    span{
                        color: var(--primary-text);
                    }
                    input{
                        border-color: var(--semi-light);
                        background: var(--semi-light);
                        color: var(--tertiary-text);
                    }
                }
            }
        }
        #pnev.CItems{
            color: var(--primary-text);
            background: var(--tertiary-box);
            .loading-container-coupon{
                background: var(--semi-dark-7);
            }
            .header1{
                background: var(--header-grad);
                color: var(--tertiary-text);
            }

            #divcheckauto{
                label{
                    input[type="checkbox"]{
                        &:after{
                            color: var(--secondary);
                        }
                        &:checked::after {
                            color: var(--primary)!important;
                          }
                    }
                }
            }
            .CItem{
                border-bottom-color:var(--semi-light);
                .CSubEv{
                    // background: var(--fourth), 0.3;
                }
                
                .COdds{
                    .CFissa{
                        .bank{
                            span{
                                color: var(--primary-text);                                 
                                background: var(--secondary);                               
                            }   
                            &.active{
                                span{
                                    background: var(--primary-btn);                                     
                                    color: var(--primary-btn-color);           
                                }                   
                            } 
                        }
                    }
                    .CDelete{
                        a{
                            color:  var(--tertiary-text);
                            &:hover{
                                color: var(--custom-red);;
                            }
                        }
                    }
                    .CQuota{
                        span{
                            color: var(--primary);
                        }
                    }
                }
            }

        }
        .slide-updown{
            background: var(--secondary);
            div.text{
                color: var(--custom-red);;
                &::before{
                    // color: var(--primary);
                }
            }
        }

        
        .footercp{
            color: var(--primary-text);
            background: var(--tertiary-box);
            .row_footer_bt{
                > div{
                    div.btn{
                        background:var(--secondary-btn);
                        color: var(--secondary-btn-color);
                        &:hover{
                            color: var(--secondary-btn-color);
                        }
                        &.active{
                            background: var(--tertiary-box);
                            color: var(--secondary-btn-color);
                        }
                    }
                }
            }
            #pn_sing_int{
                #qtint,
                #qtsolo{
                    > div{
                        div{
                            &.footer_dx{
                                span:not(#lbl_comb) {
                                    // background: var(--primary-btn);
                                    color: var(--primary-text)!important;
                                }
                                input{
                                    border-color: var(--primary);
                                    color: var(--primary-text);
                                    background-color: var(--trasp)!important;
                                }
                            }
                        }
                    }
                }
                #qtint{
                    #panel_importo_sis{
                        .footer_dx{
                            span{
                                background: var(--trasp);
                            }
                        }
                    }
                    #lbl_comb{
                        background: var(--trasp);
                    }
                }
            }
 
            #pn_sis_rid {
                div {
                    input{
                        border-color: var(--primary);
                        color: var(--primary-text);
                        background-color: var(--trasp)!important;
                    }
                    input[type="checkbox"] {
                        &:after {
                            // background: var(--secondary);
                            color: var(--secondary);
                        }
                        &:checked::after {
                            color: var(--primary)!important;
                        }
                    }
                    .cp_sis_cont {
                      .rigasistema2 {
                        span {
                          &.TSVin {
                            span {
                              background: var(--primary-btn);
                              color: var(--primary-btn-color);
                            }
                          }
                        }
                      }
                    }
                    
                  }
                  .CpnTipoRiepDbl {
                    .RiepSX {
                    }
                    .RiepDX {
                      div {
                        span {
                            span{
                                background: var(--primary-btn);
                                color: var(--primary-btn-color);
                            }
                            
                        }
                        &:nth-child(2){
                            span{
                                background: var(--primary-btn);
                                color: var(--primary-btn-color);
                            }    
                        }
                      }
                    }
                  }
            }
            .row_footer_bt1{
                .footer_sx_bt,
                .footer_dx_bt{
                    .btn{
                        &.btn-scommetti{
                            background-color: var(--primary-btn);
                            color: var(--primary-btn-color);
                        }
                        &.btn-reset{
                            background-color: var(--secondary-btn);
                            color: var(--secondary-btn-color);
                        }
                    }
                }
                
                .footer_save_cp{        
                    p{
                        border-top-color: var(--secondary);                               
                        background: var(--secondary);
                        color:var(--fourth-text);
                    }
                    .btn.btn-salva{
                        border-color: var(--secondary);
                        background: var(--trasp);
                        span{
                            background: var(--primary-btn);
                            color: var(--primary-btn-color);
                        }
                    }                    
                    
                }
            }

           
        }

        .importo-tot-coupon{
            background: var(--tertiary-box);
            .row-importo{
                color: var(--primary-text);
                .row-dx{
                    background: var(--primary-btn);
                    color: var(--primary-btn-color);
                    // span{
                    //     background: var(--primary-btn);
                    //     color: var(--primary-btn-color);
                    // }
                }
            }    
            
            .row-btn {
                .row-sx{
                    .btn{
                        background: var(--secondary-btn);
                        color: var(--secondary-btn-color);
                    }
                }
            }
        }

        #girocontoautomatico{
            background: var(--tertiary-box);
            color: var(--primary-text);
            .saldo-utente{
                span{
                    color: var(--primary);
                }
            }
        }

        .riepilogo-schedina,
        .riepilogo-prenotazione,
        .ricarica-sched{
            color: var(--primary-text);
            background-color: var(--tertiary-box);
            .header,
            .panel-heading{
                @include heading-small;
            }

            #bodyricschedina{
                .row-sched{
                    border-bottom-color: var(--semi-light);
                    &:hover{
                        // background: light (tertiary-box, 2%);
                        color: var(--primary);
                    }
                }
            }

        }
        .riepilogo-schedina{
           #bodycoupon{
                .cod-scomm{
                    .dx-img{
                        div{
                            span{
                                background: var(--primary-btn);
                                color: var(--primary-btn-color);
                            }
                        }
                    }
                }
           }
        }

        .riepilogo-prenotazione{
            .panel-body{
                .testo-prenotazione{
                    h5{
                        color: var(--primary);
                    }
                    h3{
                        color: var(--primary);
                    }
                }
            }
        }
    }

    .right-container{
        .panel.verifica-scommessa-container{            
            background: var(--tertiary-box);
            .panel-heading{
                @include heading-small;
            }
            .panel-body{
                .form-group{
                    input{
                        border-color: var(--semi-light);
                        background: var(--semi-light);
                        color: var(--tertiary-text);
                    }
                }
            }
        }

        .ultime-vincite-container.panel{
            background: var(--tertiary-box);
            .panel-heading{
                @include heading-small;
            }
        }

    }
    .box-lottobet {
        #loaderlottobet {
            background: var(--tertiary-box);
            color: var(--primary-text);
        }
        .lotto-win-container {
            background: var(--tertiary-box);
            .panel-heading{
                @include heading-small;
            }
            .panel-body {
                color: var(--primary-text);
            }
        }
        
    }

    &.qbet{
        .container.qbet-page{
            .heading-qbet{
                color: var(--tertiary-text);
            }

            .row{
                .col-sm-12 {
                    .qbet-wrapper{
                        .code-cont{
                            background: var(--tertiary-box);
                            span.title{
                                @include heading-small;
                            }
                            li{                                       
                                border-bottom-color: var(--semi-light);                     
                                span{
                                    color: var(--primary-text);
                                    &:first-child {                                        
                                        color: var(--primary);
                                        border-right-color: var(--semi-light);
                                    }
                                }
                            }
                        }
                    }

                    .legenda-qbet{
                        background: var(--tertiary-box);
                        color: var(--primary-text);
                       .panel-heading{
                            @include heading-small;
                       } 
                    }
                }
            }
        }
    }
}


/********************/
/*** MOST PLAYED ****/
/********************/ 

body {
    .piu-giocate-container,
    .ultimo-minuto-container{
        color: var(--primary-text);
        background: var(--trasp);  
        .panel-heading{
            @include heading-big;
        }
        .panel-body{
            ul.nav.nav-tabs{               
                background: var(--tertiary-box);
                li{
                    &.active{

                        a{
                            color: var(--primary);
                            background: var(--trasp)!important;
                            &:before{
                                //filter: drop-shadow(0 0 6px var(--primary));  
                                box-shadow: 0 0 6px 3px var(--primary);
                                -webkit-box-shadow: 0 0 6px 3px var(--primary);  
                            }
                        }
                    }
                    a{
                        color: var(--primary-text);
                        background: var(--trasp)!important;
                        &:after{
                            background: var(--semi-light);
                        }
                        &:hover{
                            color: var(--primary);
                        }
                    }
                }
            }

            .tab-content{    
                background: var(--tertiary-box);      
                .sub-title{
                    .time{
                        color: var(--trasp);
                    }
                    .event{
                        color: var(--trasp);
                    }
                }  
                
                ul{
                    li{                     
                        .row-partita{                                
                            border-bottom-color: var(--semi-light);
                            &:hover{
                                background-color: var(--semi-dark-5);
                                //background-color: dark (tertiary-box, 5%);
                                .titolo-partita{
                                    span{
                                        color: var(--primary);
                                    }
                                }
                            }
                            .quote-partita{
                                ul{
                                    li{
                                        span{
                                            &.qta{
                                                // border-color: var(--light), 0.2;
                                                border-color: var(--secondary);
                                                background: var(--secondary);
                                                &:hover{
                                                   // background: light (tertiary-box, 15%);
                                                   background: var(--semi-dark-2);
                                                }
                                                &.spread{
                                                    background: var(--trasp);
                                                    color:  var(--primary);
                                                    border-color: var(--trasp);
                                                }
                                            }
                                        }
                                    }
                                }
                                .piu{
                                    color: var(--trasp);
                                    background: var(--trasp); 
                                    span{
                                        color: var(--trasp);
                                        background: var(--trasp); 
                                        &:before{
                                            color: var(--primary);
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/********************/
/**** PALINSESTO ****/
/********************/ 

body {
    .palinsesti-container{
        color: var(--primary-text);
        // background: var(--secondary);    
        .main-palinsesti{
            background: var(--tertiary-box);
        }
        .heading-sport{
            background: var(--header-grad);
            .title-heading{
                color: var(--tertiary-text);
            }
            .icon-container{
                > div{
                    a{
                        color: var(--primary-text);
                        &:hover{
                            color: var(--primary);
                        }
                    }
                }
            }
        }
        .pal-heading{
            background: var(--secondary);
            .title{
                h4{                                   
                    color: var(--primary-text);
                }
            }
            .icon-container-sub{
                > div{
                    a{
                        color: var(--primary-text);
                        &:hover{
                            color: var(--primary);
                        }
                    }
                }
            }
        }
        ul.pal-container{
            background: var(--secondary);
            .check{
                li{
                    div{
                        label{
                            input[type="checkbox"]{
                                &:after{
                                    color: var(--tertiary-box);
                                }
                                &:checked::after {
                                    color: var(--primary)!important;
                                  }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*************************/
/**** QUOTE CONTAINER ****/
/*************************/ 

body.quote-page{
    .wrapper-boxed-quote{
        section#main{
            .container-fluid.quote-page{
                .main-container-quote{
                    .main-quote{
                        .palinsesto-container{
                            .top-container{                                
                                background: var(--tertiary-box);
                                color: var(--primary-text);
                                .sports{
                                    @include heading-big;
                                }
                                .title-pal{
                                    span{
                                        color: var(--tertiary-text);
                                    }
                                    .icon-container{
                                        a{
                                            &:hover{
                                                color: var(--primary);
                                            }
                                        }
                                    }
                                }                                
                            }
                            .mercati-container{
                                background: var(--secondary);
                                .nav-tabs{
                                    // display: flex;
                                    border-bottom-color: var(--semi-light);
                                    li{
                                        a{
                                            color: var(--primary-text);
                                        }
                                        &:hover,
                                        &.active{
                                            a{
                                                background: var(--trasp);
                                                color: var(--primary);
                                                border-color: var(--primary);
                                            }
                                        }
                                    }
                                }
                                .tab-content{
                                    .btn{
                                        background: var(--tertiary-box);
                                        a{                                            
                                            color: var(--primary-text);
                                        }
                                        &:hover{
                                            a{
                                                color: var(--tertiary-text);
                                            }

                                        }
                                        &.btn-mercato-attivo{
                                            a{
                                                color: var(--primary-btn);
                                            }
                                        }
                                    }                                       
                                }
                            }
                            .eventi-container{
                                .quote-eventi{     
                                    
                                    color: var(--primary-text);

                                    span.int-quote{
                                        // background: linear-gradient(90deg, var(--tertiary), var(--secondary) 50%);
                                        color: var(--tertiary-text);
                                        background: var(--tertiary-box);
                                    }
                                    table{
                                        background:  var(--secondary);
                                        thead{
                                            tr{
                                                &:first-child{
                                                    background: var(--trasp);
                                                }
                                                &:nth-child(2){
                                                    background: var(--fourth);
                                                    .segni1{
                                                        color: var(--primary);
                                                    }
                                                }
                                                th{
                                                    &.id1{
                                                        &:first-child{
                                                            // color: var(--trasp);
                                                        }
                                                    }
                                                    &.orario{
                                                        // color: var(--trasp);
                                                    }
                                                    &.data{
                                                        color: var(--primary);
                                                        &:first-child{
                                                            color: var(--primary-text);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        tbody{
                                            tr{
                                                &.tr-quote{             
                                                    border-bottom-color: var(--semi-light);
                                                    &:first-child{
                                                        border-bottom-color: var(--semi-light)!important;    
                                                    }
                                                    &:hover{
                                                        //background-color: light tertiary-box, 2%);
                                                        background-color: var(--semi-dark-2);
                                                        .id,
                                                        .orario,
                                                        .evento{
                                                            color: var(--primary);
                                                        }
                                                    }
                                                    td{
                                                        &.quotapremuta,                                                   
                                                        &.quota{
                                                            a.qta{
                                                                // box-shadow: inset 0 0 0 1px rgba(255,255,255,0.08);
                                                                background: var(--tertiary-box);
                                                                color: var(--primary-text);
                                                                &:hover{
                                                                    //background: light tertiary-box, 15%);
                                                                    background: var(--semi-dark-2);
                                                                    color: var(--tertiary-text);
                                                                }

                                                                &.quota1_a {
                                                                    color: var(--primary-text);
                                                                }

                                                                &.quota2_a {
                                                                    color: rgb(195, 113, 241);
                                                                }
                                                                
                                                                &.quota3_a {
                                                                    color: rgb(22, 199, 0);
                                                                }
                                                                
                                                                &.quota4_a {
                                                                    color:rgb(0, 167, 160);
                                                                }
                                                                
                                                                &.quota5_a {
                                                                    color: rgb(210, 47, 6)
                                                                }
                                                            }
                                                        } 
                                                        &.quotapremuta{
                                                            a{
                                                                background: var(--primary-btn)!important;
                                                                color: var(--primary-btn-color)!important;
                                                                &:hover{
                                                                    background: var(--semi-dark-2);
                                                                    color: var(--tertiary-text)!important;
                                                                }
                                                            }
                                                        } 
                                                        &:first-child{
                                                            div{
                                                                color: var(--primary);
                                                            }
                                                        }
                                                        &:last-child{
                                                            div{
                                                                color: var(--primary);
                                                            }
                                                        }
                                                        &.segni{
                                                            .qta-dp{
                                                                > div.segniqt {
                                                                    // box-shadow: inset 0 0 0 1px rgba(255,255,255,0.08);
                                                                    background: var(--tertiary-box);
                                                                    .nome-quota{
                                                                        color: var(--primary-text);
                                                                    }
                                                                    .tipo{
                                                                        color: var(--primary);
                                                                    }
                                                                    &:hover{
                                                                        //background: light (tertiary-box, 15%);
                                                                        background:var(--semi-dark-2);
                                                                        color: var(--primary-text);
                                                                    }
                                                                }
                                                            }
                                                            &.combo{
                                                                
                                                            }
                                                        }

                                                        &.id1{
                                                         color: var(--primary); 
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }        
                    
                    .legenda-main{
                        background: var(--tertiary-box);
                        color: var(--primary-text);                        
                        .content-legenda{
                            .title{
                                color: var(--primary);
                            }
                            .list-inline{
                                li{
                                    box-shadow: inset 0 0 0 1px var(--semi-light-2);
                                    background: var(--secondary);
                                    &.riga1{color:#e570e7}
                                    &.riga2{color: var(--primary-text)}
                                    &.riga3{color:#299a0b}
                                    &.riga4{color:#4096ee}
                                    &.riga5{color:#cc0000}
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/**********************/
/**** SPORT FILTER ****/
/**********************/ 

body{
    .tpc,
    .tpq{
        &.selettore-giorni{        
            background: var(--tertiary-box);
            .title{
                background: var(--header-grad);
                color: var(--tertiary-text);
            }

            //tpq
            .slider.slider-horizontal{
                .slider-track{
                    .slider-selection.tick-slider-selection{
                        background: var(--primary);
                    }
                    .slider-handle{
                        //background: dark(primary, 20%);
                        background: var(--primary);
                    }

                    .slider-tick.in-selection{
                        //background: dark (primary, 10%);
                        background: var(--primary);

                    }
                }
                .slider-tick-label-container{
                    .slider-tick-label{
                        color: var(--primary-text);
                    }
                }
            }
            //tpc
            ul{
                background: var(--tertiary-box);
                li{
                    a.btn{                        
                        color: var(--tertiary-text);
                        // background: light (secondary, 3);
                        background: var(--semi-light-3);
                        &.active{
                            background: var(--primary-btn);
                            color: var(--primary-btn-color)
                        }
                    }
                }
            }
        }
    }

    .tpS{
        background: var(--tertiary-box);
        select{
            background-color: var(--semi-light);
            color: var(--primary-text);
            border-color: var(--semi-light);
            &:focus,
            &:active,
            &:visited{
                outline: unset;
                box-shadow: unset;
            }
            option{
                background: var(--dropdown);
            }
        }
        span{
            i{
                &.fa-times{
                    color: var(--custom-red);;
                }
                &.fa-star{
                    color: var(--primary);
                }
            }
        }
    }
}

/**************************/
/**** SPORT MENU INDEX ****/
/**************************/ 
body,
body.index,
body.mainsport,
body.quote-page  {   

    .search-box{
        background: var(--tertiary-box);
        .form-group{            
            input{
                &#txtcercaweb{
                    background: var(--semi-light);
                    color: var(--primary-text);
                }
                &.btn{
                    color: var(--primary-text);
                    background: var(--trasp);
                }
            }
            &:after {
                color: var(--primary-text);
              }
        }
    }
    a.btn.btn-expiring-events{
        background: var(--tertiary-box);
        span{
            background: var(--primary-btn);
            color: var(--primary-btn-color);
        }
    }   

    .btn-live-score,
    .btn-stats{
        background: var(--secondary-btn);
        color: var(--secondary-btn-color);
        span{
            &:before{
                color: var(--primary);
            }
        }
    }

    .navbar-default.sidebar{        
        background: var(--trasp);
        color: var(--primary-text);
        .sidebar-nav{
            .page-header{
                @include heading-small;
            }
            ul{
                background: var(--tertiary-box);
                li{
                    a{
                        color: var(--primary-text);
                        border-bottom-color: var(--semi-light);
                        background: var(--trasp)!important;
                        &:hover{
                            background: var(--trasp);
                            color: var(--primary);
                        }
                    }
                }
            }
        }
    }

    ul.betting-menu-4{
        background: var(--trasp);
        color: var(--primary-text);
        li{
            &.header{
                background: var(--header-grad);
                color: var(--tertiary-text);
                span{
                    color: var(--tertiary-text);
                }
            }           

            &.level-1{
                background: var(--tertiary-box);
                a{
                    color: var(--primary-text);
                    border-top-color: var(--semi-light);
                    &.active,
                    &:hover{
                        background: var(--trasp);
                        color: var(--primary);
                    }
                    span{
                        &.event-no{
                            border-color: var(--primary);
                        }
                    }
                   
                }
                ul{
                    li.level-2{

                        ul.treeview-menu{
                            background: var(--secondary);
                            li{
                                border-bottom-color: var(--tertiary-box)!important;
                                a{
                                    span{
                                        border-color: var(--trasp);
                                    }
                                }
                            }
                        }
                    }
                }
            }            
        }

        &.extra{
            li.level-1{
                &:last-child{
                    a{
                        border-bottom-color: var(--semi-light);
                    }
                }
            }
        }
    }

    .menu-sport-palinsesti{
        .menu-title{
            background: var(--header-grad);
            color: var(--tertiary-text);
            color: var(--tertiary-text);
        } 
        ul.nav-palinsesti.left{
            background: var(--trasp);
            color: var(--primary-text);
            li{  
                &.secondoli,  
                &.menu-item.primoli{
                    background: var(--tertiary-box);
                    p{
                        color: var(--primary-text);
                        border-top-color: var(--semi-light);
                        &.active,
                        &:hover{
                            background: var(--trasp);
                            color: var(--primary);
                        }
                        span{
                            &.event-no{
                                border-color: var(--primary);
                            }
                        }
                    
                    }
                    ul{
                        li.level-2{

                            ul.treeview-menu{
                                background: var(--secondary);
                            }
                        }
                    }
                }    
                &.secondoli{
                    span{
                        &.event-no{
                            border-color: var(--primary);
                            color: var(--primary-text);
                        }
                    }
                    ul.menu-ter-lev{                        
                        li{
                            background: var(--secondary);
                            border-bottom-color:var(--tertiary-box)!important;
                            &:hover{
                                p.terzop{
                                    color: var(--primary);    
                                }
                                span.event-no{
                                    color: var(--primary);
                                }
                            }
                            &.menu-attivo{
                                p.terzop{
                                    color: var(--primary);    
                                }
                                span.event-no{
                                    color: var(--primary);
                                }
                            }
                        }
                    }
                }         
            }

            &.extra{
                li.level-1{
                    &:last-child{
                        a{
                            border-bottom-color: var(--semi-light);
                        }
                    }
                }
            }
        }
    }
    

}


/********************/
/**** TOP LEAGUE ****/
/********************/ 


body {
    .top-leagues{
        color: var(--primary-text);
        background: var(--tertiary-box);
        .panel-heading{
          @include heading-small;
        }
        .panel-body{
            ul{
                li{
                    a{
                        color: var(--primary-text);
                        border-bottom-color: var(--semi-light);
                        &:hover{
                            color: var(--primary);
                        }
                    }                    
                }
            }
        }
    }
}


/************************/
/**** VUE ODDS MATCH ****/
/************************/ 

body.quote-page{
    .wrapper-boxed-quote{
        section#main{
            .container-fluid.quote-page{
                .main-container-quote{
                    .main-quote{
                        .palinsesto-container{
                            &#vue-OddsMatch{
                                .evento-singolo{
                                    .panel{
                                        background: var(--tertiary-box);
                                        .panel-heading{
                                            @include heading-big;
                                            .main-title{
                                                .evento-select{
                                                    button{
                                                        border-color: var(--semi-light);
                                                        background-color: var(--semi-light);
                                                        color: var(--primary-text);
                                                    }
                                                    ul.dropdown-menu{
                                                        background: var(--primary-box);
                                                        li{
                                                            span{
                                                                color: var(--primary-text);
                                                                &:hover{
                                                                    color: var(--primary);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .panel-body{
                                            .nav-tabs{
                                                border-bottom-color: var(--semi-light);
                                                li{
                                                    a{
                                                        color: var(--primary-text);
                                                    }
                                                    &:hover,
                                                    &.active{
                                                        a{
                                                            background: var(--trasp);
                                                            color: var(--primary);
                                                            border-color: var(--primary);
                                                        }
                                                    }
                                                }
                                            }
                                            h3{
                                                color: var(--primary-text);
                                            }
                                            .tab-content{
                                                .tab-pane{
                                                    .panel{
                                                        .panel-heading{
                                                            @include heading-small;                                                            
                                                            background: var(--fourth);
                                                            color: var(--live-heading-color);
                                                            h4{
                                                                a{
                                                                    span{                                                                        
                                                                        color: var(--live-heading-color);
                                                                    }
                                                                    &:after{
                                                                        color: var(--live-heading-color); 
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        .panel-collapse{
                                                            border-color: var(--fourth);
                                                            background: transparent;
                                                            .panel-body{
                                                                color: var(--primary-text);
                                                                .odd-container{
                                                                    box-shadow: unset;
                                                                    color: var(--primary-text); 
                                                                    background: var(--primary-box);    
                                                                    .market{
                                                                        color: var(--primary);
                                                                    }
                                                                    &:hover{
                                                                        background: var(--primary-btn);
                                                                        color: var(--primary-btn-color);
                                                                        .market{
                                                                            color: var(--primary-btn-color);
                                                                        }
                                                                    }
                                                                }

                                                                &.fixed-div{
                                                                    .odds-panel{
                                                                        .odd-container{
                                                                            &.title{
                                                                                background: var(--primary-btn);
                                                                                span{
                                                                                    color: var(--primary-btn-color);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.scorer{
                                                                    .odd-container{
                                                                        &.evento-select.dropdown{
                                                                            
                                                                            button.btn{
                                                                                color: var(--primary-text);
                                                                                ::after {
                                                                                    color: var(--primary-text);
                                                                                }
                                                                            }
                                                                            &:hover{
                                                                                //background: light (tertiary-box, 2%) ;
                                                                                background: var(--semi-light-2);
                                                                            }

                                                                            ul.dropdown-menu{
                                                                                background:var(--primary-box);
                                                                                li{
                                                                                    &:hover{
                                                                                        // background: light (tertiary-box, 2%);
                                                                                        span{
                                                                                            color: var(--primary);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/************************/
/**** ULTIME VINCITE ****/
/************************/ 

body{
    .ultime-vincite-container{
        .panel-body{
            ul{
                li{
                    background: var(--secondary);
                    color: var(--primary-text);
                    .giocata-vincita{
                        color: var(--primary);
                    }
                    .vincita.btn{
                        a{
                            color: var(--primary-btn-color);
                            &:focus,
                            &:visited,
                            &:hover{
                                color: var(--primary-btn-color);
                            }
                        }
                    }
                }
            }
        }
    }
}


/************************/
/**** QUOTE FAVORITE ****/
/************************/ 


body {
    &.mainsport{
        &.oddslessthan{
            .wrapper-boxed{
                section#main{
                    .container-fluid{
                        .main-container-quote{
                            .main-container{
                                .qt-fav{
                                    color: var(--primary-text);
                                    .panel-heading{
                                        @include heading-big;
                                    }
                                    .sport-btn{
                                        ul{
                                            li{
                                                background: var(--secondary);
                                                .sport{
                                                    color: var(--tertiary-text);
                                                }
                                                .event-no {
                                                    color: var(--primary);
                                                  }
                                            }
                                        }
                                    }
                                    .sport-qt-cont{
                                        ul{
                                            //background: dark (Secondary, 2%);
                                            background: var(--semi-dark-2);
                                            li.title{
                                                background: var(--trasp);
                                            }
                                            &#OLTUlInt{
                                                @include heading-small;
                                            }
                                            &#OLTUlList{
                                                li{
                                                    border-bottom-color: var(--semi-light);
                                                    .event{
                                                        span{
                                                            &.championship{
                                                                color: var(--primary);
                                                            }
                                                        }
                                                    }
                                                    .quote{
                                                        .qta{
                                                            border-color: var(--semi-light-2);
                                                            &:hover{
                                                                //background: light (tertiary-box, 15%);
                                                                background : var( --semi-light-12);
                                                            }
                                                            &.fav{
                                                                color: var(--primary);
                                                                border-color: var(--primary);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/********************/
/**** PRINT ODDS ****/
/********************/ 

body{
    &.print-odds {
        section#main{
            .main-container{
                .page-title{
                    @include heading-big;
                }
               .stampa-quote-container{
                    
                    .title{
                        @include heading-small;
                    }
                }
                .visualizza-quote{
                    background: var(--tertiary-box);
                    ul{
                        li{

                            color: var(--primary-text);
                            .checkbox{
                                label{
                                    input[type="checkbox"]{
                                        &:after{
                                            color: var(--secondary);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .col-md-6{
                    &.pal-selezionati{
                        ul{
                            background: var(--tertiary-box);
                            li{
                                background: var(--trasp);
                                color: var(--primary-text);
                                .checkbox{
                                    label{
                                        input[type="checkbox"]{
                                            &:after{
                                                color: var(--secondary);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/****************/
/**** MODAL ****/
/***************/ 

body{
    .modal{
        .modal-dialog{
            .modal-content{
                color: var(--tertiary-text);                
                background: var(--primary-box);
                .modal-header{
                    button.close{
                        color: var(--trasp);
                        &:after,
                        &:before{
                            background: var(--custom-red);;
                        }

                    }
                    h4.modal-title{
                        color: var(--primary-text);
                    }
                }
                .modal-body{                   
                    color: var(--primary-text);                  
                    background: var(--secondary);
                    .top-container{
                        .title{
                            background: var(--tertiary-box);
                        }
                        .row{
                            background: var(--secondary);
                            border-bottom-color: var(--semi-light);
                            &:first-child{
                                border-top-color: var(--semi-light);
                            }
                        }
                    }
                    .bottom-container{
                        .title{
                            background: var(--tertiary-box);
                        }
                        .table-bottom{
                            table{
                                border-color: var(--semi-light);
                                tr{
                                    th,
                                    td{
                                        border-color: var(--semi-light);
                                        &.esito{
                                            img{
                                                
                                            }
                                        }
                                    }
                                }
                                &.table-bordered {
                                    // background: var(--primary-box);
                                    color: var(--primary-text);
                                    border: 1px solid var(--trasp);
                                    background: var(--secondary);
                                    thead,
                                    tbody,
                                    tfoot {
                                        tr{
                                            th {
                                                color: var(--primary-text);
                                                background: var(--fourth);                        
                                                border: 1px solid var(--fourth)!important;
                                            }
                                            
                                            td {
                                                border: 1px solid var(--semi-light);
                                                a {
                                                    color: var(--primary);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.content{
                        background: var(--trasp);
                        padding: 0;
                    }
                    
                }
            }
        }
    }
    .modal-backdrop{
        background:  var(--semi-light-3);
    }
}
#casino-transfer-modal.modal,
#transfer-modal.modal{
    background: var(--semi-dark-7);
    .modal-dialog{
        .modal-content{
            color: var(--primary-text);                
            // background: var(--third5);
            box-shadow: 0 0 50px 12px var(--dark);
            .modal-header{
                button.close{
                    &:after,
                    &:before{
                        background: var(--custom-red);;
                    }
                }
                h4.modal-title{
                    color: var(--primary-text);
                }
            }
            .modal-body{                  
               color: var(--primary-text);                   
            //    background: rgba(0, 147, 53, 0.8);
               .balances{
                    color: var(--primary-text);
                    ul{                                      
                        background: var(--trasp);
                        li{
                            span{
                                &.icon{
                                    color: var(--primary-text);
                                    i{
                                        &.fa-arrow-left{
                                            color: var(--custom-red);;
                                        }
                                    }
                                }
                                &.amount{
                                    color: var(--tertiary-text);
                                }
                            }
                            &.title{
                                background: var(--header-grad);
                                color: var(--tertiary-text);
                            }
                        }
                    }
                }
            }
        }
    }
}

body.areariservata{
    .modal#modalPayment {
        .modal-content{
            background: var(--primary-box);
            color: var(--tertiary-text);
            // .modal-header{
            //     button.close{
            //         &:after,
            //         &:before{
            //             background: var(--primary);
            //         }
    
            //     }
            //     h4.modal-title{
            //         color: var(--primary-text);
            //     }
            // }
            .modal-header{
                button.close{
                    &:after,
                    &:before{
                        background: var(--primary);
                    }

                }
                h4.modal-title{
                    color: var(--primary-text);
                }
            }

            .modal-body{                   
                color: var(--primary-text);                  
                background: var(--trasp);
                .bottom-container{
                    .table-bottom{
                        table{
                            border-color: var(--semi-light-2);
                            tr{
                                th,
                                td{
                                    border-color:  var(--semi-light-2);
                                    &.esito{
                                        img{
                                            filter: drop-shadow(0 0 2px  var(--semi-light-2));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.modal#modalPayment {
    .modal-content{
        background:var(--primary-box);
        color: var(--tertiary-text);
        .modal-header{
            button.close{
                &:after,
                &:before{
                    background: var(--primary);
                }

            }
            h4.modal-title{
                color: var(--primary-text);
            }
        }
        .modal-body{                   
            color: var(--primary-text);                  
            background: var(--semi-light);
            .bottom-container{
                .table-bottom{
                    table{
                        border-color:  var(--semi-light-2);
                        tr{
                            th,
                            td{
                                border-color:  var(--semi-light-2);
                                &.esito{
                                    img{
                                        //filter: drop-shadow(0 0 2px  var(--semi-light-2));
                                        box-shadow: 0 0 2px var(--semi-light-2);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



/**********************/
/*** MARKET MOVERS ****/
/**********************/ 

body {
    .market-movers-container {
        // font-family: var(--primary_font_family;
         color: var(--primary-text);
         background: var(--trasp);  
         .panel-heading{
             @include heading-big;
         }
         .panel-body{
             ul.nav.nav-tabs{               
                 background: var(--tertiary-box);
                 li{
                     &.active{
                         a{
                             color: var(--primary);
                             background: var(--trasp)!important;
                             &:before{
                                 //filter: drop-shadow(0 0 6px var(--primary));    
                                 box-shadow: 0 0 6px 3px var(--primary);
                                 -webkit-box-shadow: 0 0 6px 3px var(--primary);  
                             }
                         }
                     }
                     a{
                         color: var(--primary-text);
                         background: var(--trasp)!important;
                         &:after{
                             background: var(--semi-dark ); //rgba($light, 0.05);
                         }
                         &:hover{
                             color: var(--primary);
                         }
                     }
                 }
             }
 
             .tab-content{  
                 background: var(--tertiary-box);       
                 .chart-container{
                     .chart-market-mov-a{
                         svg{
                             text{
                             }
                         }
                     }
                     .bottom-container{
                         .odds-container{
                             ul{
                                 li{
                                     &.quota-container{    
                                         span{
                                         }
                                         .qta{
                                             background: var(--secondary);
                                             &.qta-up{
                                                 color: $verde-vin;    
                                             }
                                             &.qta-down{
                                                 color: var(--custom-red);    
                                             }
                                         }
                                     }
                                 }
                             }
                         }
                     }
                 }
             }
         }
     }
 }
 
/**********************/
/*** MARKET MOVERS ****/
/**********************/ 

body {
   .market-movers-container {
       // font-family: $primary_font_family;
        color: var(--primary-text);
        background: transparent;  
        .panel-heading{
            @include heading-big;
        }
        .panel-body{
            ul.nav.nav-tabs{               
                background: var(--tertiary-box);
                li{
                    &.active{
                        a{
                            color: var(--primary);
                            background: transparent!important;
                            &:before{
                                filter: drop-shadow(0 0 6px var(--primary));    
                            }
                        }
                    }
                    a{
                        color: var(--primary-text);
                        background: transparent!important;
                        &:after{
                            background: var(--semi-light);
                        }
                        &:hover{
                            color: var(--primary);
                        }
                    }
                }
            }

            .tab-content{  
                background: var(--tertiary-box);       
                .chart-container{
                    .chart-market-mov-a{
                        svg{
                            text{
                            }
                        }
                    }
                    .bottom-container{
                        .odds-container{
                            ul{
                                li{
                                    &.quota-container{    
                                        span{
                                        }
                                        .qta{
                                            background: var(--secondary);
                                            &.qta-up{
                                                color: $verde-vin;    
                                            }
                                            &.qta-down{
                                                color: var(--custom-red);    
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/***********************/
/*** C-INFO-AGENZIA ****/
/***********************/ 

body {
    .info-agenzia-container {
        // font-family: $primary_font_family;
         color: var(--primary-text);
         background: transparent;  
         .panel-heading{
             @include heading-big;
             .benvenuti-btns{
                a{
                    color: var(--tertiary-text);
                    &:hover{
                        color: var(--primary);
                    }
                }
             }
         }
         .panel-body{
            background: var(--tertiary-box);
            color: var(--primary-text);
            .table-responsive{
                .table{
                    margin-bottom: 0;
                    background: var(--secondary);
                    thead{
                        tr{
                            th{
                                background: var(--fourth);
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                border-color: var(--semi-light);
                            }
                        }    
                    }
                    tfoot{
                        background: var(--fourth);
                        tr{
                            td{
                                border-color: var(--semi-light);
                            }
                        }  
                    }
                }    
            }
         }
     }
 }
 

 .ricerca-sport{
    color : var(--primary-text);
    .page-title{
        @include heading-big
    }
    .table-responsive{
        table{
            border-color: var(--semi-light);            
            background: var(--tertiary-box);
            tr{
                td,th{
                    border-color: var(--semi-light);
                }
                th{
                    background: var(--secondary);    
                }
            }
        }
    }
 }