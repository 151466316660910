// Colors and Backgrounds
$light : #ffffff;
$dark : #000000;
$trasp : transparent;
$semi-dark : rgba(0,0,0,0.25);
$semi-light : rgba(255,255,255,0.25);

$soft-light : #ebebeb;
// Header

$header-color : #15191C;
$header-top : #121517;
$header-menu : #2a2f35;

$header-text-primary: #000;
$header-text-secondary: #fff;

$primary-box : #15191C;
$secondary-box : #161718;
$tertiary-box: #1f2327;
$fourth-box: #212528;

$primary-btn: #e7a723;
$secondary-btn : #343a40;

$live-selected: #e7a723;

$primary: #e7a723;
$secondary: #2a2f35;
$tertiary-opacity: 0.6;
$tertiary: rgba($primary, $tertiary-opacity);
$fourth: #282728;

//gradients
$third4: linear-gradient(to bottom,$primary,#4373b1);

// $third4: linear-gradient(to bottom,$primary,adjust-hue($primary, 90deg));

$third5: linear-gradient(45deg, #151841 20%, #4373b1 60%, $primary 80%,#024f1b 100%);

$header-grad: linear-gradient(90deg,$tertiary, $secondary 50%);

$panel-header: linear-gradient(to right, rgba(lighten($primary, 2%), 0.9), transparent);


$primary-text: #c1c1c1;
$secondary-text: #000;
$tertiary-text: #fff;
$fourth-text: #777;


$primary-btn-color:  #111;
$secondary-btn-color: #fff;

$heading-color: #fff;
$live-heading-color: #c1c1c1;

$text-light: #000;
$text-dark: #FFF;

$text-custom: green;


$custom-bg: #212334;
$custom-red: #fb3754;
$custom-green: #0b840b;


$dropdown: #000114;

// $alpha-white: rgba(255,255,255,0.2);
// $alpha-white-3: rgba(255,255,255,0.3);
// $alpha-white-12: rgba(255,255,255,0.12);
// $alpha-white-05: rgba(255,255,255,0.05);
// $alpha-white-08: rgba(255,255,255,0.08);
// $alpha-black: rgba(0,0,0,0.2);
// $alpha-black-3: rgba(0,0,0,0.3);
// $alpha-black-8: rgba(0,0,0,0.8);
// $alpha-black-7: rgba(0,0,0,0.7);

$bg-white: #fff;
$bg-black: #000;


// $primary_font_family: 'gilroy', sans-serif;


// Colors for labels 

$yellow : #f6dc43;
$blue : #0a3bad;
$verde-vin:#04cd06;
$verde-pag: #138301;
$rosso: #f00;
$arancione: #e16300;

// CDN Images ( switch to .. in production )

//$img_url : 'https://global.cdn000.net/';

// Input

$primary-input : rgba(255,255,255,0.05);
$secondary-input : #15191C;
// $tertiary-input : ;