body {
/*****************
HEADER 5 GLOBAL -
*****************/
    .header-qt.header-5 {
        background: var(--header-color);
        .tophd {
            background: var(--header-top);
            > .container-fluid {
                .transfer-container {
                    button {
                        background: var(--secondary-btn);
                        color: var(--secondary-btn-color);
                    }
                }

                .login-box.loggato {
                    color: var(--light);
                    &:after{
                        color: var(--custom-red);
                        border-right-color: var(--semi-light)!important;
                    }
                    .user-icon {
                        // background: var(--dropdown;
                        &.collapsed{
                            // background: var(--trasp);
                        }
                        p{
                            color: var(--header-text-primary);
                        }
                        &:after{
                            border-right-color: var(--semi-light)!important;
                        }
                        i {
                            color: var(--primary);
                            background: var(--secondary);
                        }
                    }
                    .disp-user {                        
                        background: var(--dropdown);
                        box-shadow: 0 0 5px 1px var(--semi-dark-3);
                        ul.list-inline {
                            li {
                                // border-bottom-color:  var(--semi-light);
                                span{
                                    color: var(--header-text-secondary);
                                    &.img-ref {
                                        color: var(--primary);
                                    }
                                }
                                
                                &:hover{
                                    background: var(--semi-light);
                                }
                            }
                        }
                    }

                    .btn.btn-logout {
                        background: var(--trasp);
                        color: var(--custom-red);
                    }

                    .notification{
                        &:after{          
                            border-right-color: var(--semi-light);
                        }
                        a{
                            color: var(--primary-text);
                            span{
                                &.number{
                                    background: var(--custom-red);
                                }
                            }
                        }
                        
                        ul.dropdown-menu{
                            background: var(--dropdown);
                            li{
                                a{                                    
                                    color: var(--primary-text);
                                    &:hover{
                                        background: var(--semi-light);
                                    }
                                }
                            }
                        }
                    }
                    
                }

                .form-inline.login-box {
                    .form-group {
                        input {
                            background: var(--semi-dark-3);
                            color: var(--semi-light-8);
                            &::placeholder {
                                color: var(--semi-light-8);
                            }
                        }
                    }
                    div.rec-password {
                        border-color:  var(--primary);
                        color: var(--primary-text);
                        > div {
                            a.rec-password {
                                color: var(--header-text-primary);
                                span {
                                    &:hover{
                                        color: var(--primary);
                                    }
                                }
                            }
                        }
                    }
                    .btn {
                        &.btn-login {
                            background: var(--primary-btn);
                            color: var(--primary-btn-color);                            
                        }
                        &.dropdown-toggle,
                        &.btn-registrazione {
                            background: var(--secondary-btn);
                            color: var(--secondary-btn-color);
                        }
                    }


                    .register.register-cont {
                        .settings.register.dropdown {                            
                            > ul.dropdown-menu {
                                li {
                                    ul.register {
                                        li {
                                            a {
                                                // background: var(--primary);
                                                // color: var(--tertiary-text);
                                                background: var(--primary-btn);
                                                color: var(--primary-btn-color);
                                                &:focus,
                                                &:hover{
                                                    color: var(--primary-btn-color);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .col-md-2.settings-container {
                    .settings.dropdown {
                        > .btn {
                            background: var(--trasp);
                            color: var(--light);  
                        }

                        > ul.dropdown-menu {
                            background: var(--dropdown);
                            li{
                                ul{
                                    li{
                                        &.active{
                                            color: var(--primary);
                                        }
                                        
                                        &:hover{
                                            background: var(--semi-light);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.odds-type,
                    &.clock-box {
                        .settings.dropdown{
                            button{
                                span{                                    
                                    color: var(--header-top-text-color);
                                }
                            }
                            ul.dropdown-menu{
                                li{
                                    ul{
                                        li{
                                            &.active{
                                                color: var(--primary);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }

                .settings-fullscreen {
                    color: var(--light);
                    cursor: pointer;
                    &:hover {
                        color: var(--primary);
                    }
                }

                .settings-colors-skin {
                    ul {
                        li {
                            &.skin-color--red {
                                background-color: #ff3f44;
                            }
                            &.skin-color--blue {
                                background-color: blue;
                            }
                            &.skin-color--yellow {
                                background-color: yellow;
                            }
                            &.skin-color--green {
                                background-color: green;
                            }
                            &.skin-color--purple {
                                background-color: purple;
                            }
                        }
                    }
                }

                .settings-dark-light {

                    .switch {
                        background: var(--primary);
                        span {
                            background: var(--light);
                        }
                    }

                    label {
                        color: var(--light);
                    }
                }
            }
        }

        div.midhd {
            .row {

                .col-md-9 {
                    .mid-menu {
                        > ul.list-inline {
                            > li {
                                a {
                                    color: var(--header-top-text-color);
                                    i {
                                        color: var(--header-top-icon-color);
                                    }
                                    span {
                                        &:before{
                                            color: var(--header-top-icon-color);
                                        }
                                    }
                                    &:hover {
                                        //color: var(--primary);
                                        opacity: 0.8;
                                    }
                                    &:before{
                                        color: var(--header-top-icon-color);
                                    }
                                }


                                span.label-ctm {
                                    color: var(--dark);
                                    background: var(--yellow);
                                }
                            }
                        }
                    }

                }
            }
        }

        .navbar {
            &.navbar-qt {
                background: var(--header-menu);
                > div {
                    .navbar-collapse {
                        ul.nav.navbar-nav {
                            &#vueMenuHeader{
                                > li {
                                    a {
                                        color: var(--header-text-primary);
                                        i {
                                            color: var(--text-custom);
                                        }
                                        span {
                                            &::before {
                                                color: var(--primary);
                                            }
                                        }
                                    }
                                    &:hover {

                                        a {
                                            background: var(--primary-btn);
                                            color: var(--primary-btn-color);
                                            i {
                                                color: var(--primary-btn-color);
                                            }
                                            span {
                                                &:before{
                                                    color: var(--primary-btn-color);
                                                }
                                                color: var(--primary-btn-color);
                                            }
                                        }

                                    }

                                }
                            }


                            > li {
                                a {
                                    color: var(--header-text-primary);
                                    &::before {
                                        color: var(--primary);
                                    }                                    
                                    i {
                                        //color: var(--text-custom);
                                        color: var(--secondary-text);
                                    }
                                    // span {
                                    //     &::before {
                                    //         color: var(--primary);
                                    //     }
                                    // }
                                }

                                &.account-static{
                                    a{
                                        &::before{
                                            color: var(--primary-text)!important;
                                        }
                                    }
                                    &:hover {
                                        a {
                                            background: var(--trasp);
                                            color: var(--primary-text);     
                                            &::before{
                                                color: var(--primary-text)!important;
                                            }                                       
                                        }
                                    }
                                }

                                &:hover {

                                    a {
                                        background: var(--primary);
                                        color: var(--primary-btn-color);
                                        &:before {
                                            color: var(--primary-btn-color);
                                        }
                                        i {
                                        // span {
                                        //     &:before{
                                        //         color: var(--tertiary-text);
                                        //     }
                                            color: var(--primary-btn-color);
                                        }
                                    }
                                
                                }

                            }

                        }
                    }

                    .div-versa {
                        a {
                            i {
                               // color: var(--text-custo);
                               color: var(--secondary-text);
                            }
                        }

                        // &:hover {

                        //     a {
                        //         background-color: var(--primary);
                        //         color: var(--secondary);
                        //         i {
                        //             color: var(--primary-text);
                        //         }
                        //         span {
                        //             color: var(--primary-text);
                        //         }
                        //     }
                        
                        // }
                    }

                    .div-account {
                        &:after{
                            border-right-color: var(--semi-light)!important;
                        }
                        a{                            
                            color: var(--header-text-primary);                            
                            &:before{
                                color: var(--header-text-primary);
                            }
                        }                        
                    }
                }
            }
        }
    }

    /* Skin Light */
    &.skin--light {
        .header-qt.header-5 {
            background-color: var(--semi-light-8); //var(--soft-light);
            .tophd {
                background-color: var(--light);
                .settings-fullscreen {
                    color: var(--dark);
                }

                .settings-dark-light {
                    span {
                        color: var(--dark);
                    }
                    
                }

                .login-box {
                    span,
                    p {
                        color: var(--dark);
                    }
                    color: var(--dark);
                }


            }

            .midhd {
                ul.list-inline {
                    span {
                        color: var(--dark);
                    }
                }                
            }

            .navbar.navbar-qt {
                background: var(--light);
                span,
                a {
                    color: var(--dark);
                }
            }

        }
    }
}

/* Header Menu Selection Active */
body{
    header{
        .header-qt.header-5{
            .navbar.navbar-qt {
                > div {
                    ul.nav.navbar-nav {
                        > li:hover a{
                            background: var(--primary-btn);
                            span{
                                color: var(--primary-btn-color);
                            }
                        }
                    }
                }
            }
        }
    }
    &.index{
        header{
            .header-qt.header-5{
                .navbar.navbar-qt {
                    > div {
                        ul.nav.navbar-nav {
                            > li:first-child a,
                            > li:first-child a,
                            > li.home a{
                                background: var(--primary-btn);
                                color:var(--primary-btn-color);
                                &:before{
                                    color:var(--primary-btn-color)!important;
                                }
                                span{
                                    color: var(--primary-btn-color);
                                    &:before{
                                        color:var(--primary-btn-color)!important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.quote-page,
    &.mainsport{
        header{
            .header-qt.header-5{
                .navbar.navbar-qt {
                    > div {
                        ul.nav.navbar-nav {
                            > li:nth-child(2) {
                                a{
                                    background: var(--primary-btn);
                                    color: var(--primary-btn-color);
                                    &:before{
                                        color:var(--primary-btn-color)!important;
                                    }                                    
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.live{
        header{
            .header-qt.header-5{
                .navbar.navbar-qt {
                    > div {
                        ul.nav.navbar-nav {
                            > li:nth-child(3) {
                                a{
                                    background: var(--primary-btn);
                                    color: var(--primary-btn-color);
                                    &:before{
                                        color:var(--primary-btn-color)!important;
                                    } 
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.casino{
        background: var(--primary-box);
        header{
            .header-qt.header-5{
                .navbar.navbar-qt {
                    > div {
                        ul.nav.navbar-nav {
                            > li:nth-child(4) {
                                a{
                                    background: var(--primary-btn);
                                    color: var(--primary-btn-color);
                                    &:before{
                                        color:var(--primary-btn-color)!important;
                                    } 
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.casino.casino-live{
        background: var(--primary-box);
        header{
            .header-qt.header-5{
                .navbar.navbar-qt {
                    > div {
                        ul.nav.navbar-nav {
                            > li:nth-child(4) {
                                a{
                                    background: var(--trasp);
                                    color:var(--primary-text);
                                    &:before{
                                        color:var(--primary);
                                    }
                                    span{
                                        color: var(--primary-text);
                                        &:before{
                                            color:var(--primary)!important;
                                        }
                                    }
                                }
                                &:hover{
                                    background: var(--trasp);
                                    color:var(--primary-text);
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                            > li:nth-child(5) {
                                a{
                                    background: var(--primary-btn);
                                    color:var(--primary-btn-color);
                                    &:before{
                                        color:var(--primary-btn-color)!important;
                                    }
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.casino.cat-11{
        background: var(--primary-box);
        header{
            .header-qt.header-5{
                .navbar.navbar-qt {
                    > div {
                        ul.nav.navbar-nav {
                            > li:nth-child(4) {
                                a{
                                    background: var(--trasp);
                                    color:var(--primary-text);
                                    &:before{
                                        color:var(--primary);
                                    }
                                    span{
                                        color: var(--primary-text);
                                        &:before{
                                            color:var(--primary)!important;
                                        }
                                    }
                                }
                                &:hover{
                                    background: var(--trasp);
                                    color:var(--primary-text);
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                            > li:nth-child(6) {
                                a{
                                    background: var(--primary-btn);
                                    color:var(--primary-btn-color);
                                    &:before{
                                        color:var(--primary-btn-color)!important;
                                    }
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.casino.poker{
        background: var(--primary-box);
        header{
            .header-qt.header-5{
                .navbar.navbar-qt {
                    > div {
                        ul.nav.navbar-nav {
                            > li:nth-child(4) {
                                a{
                                    background: var(--trasp);
                                    color:var(--primary-text);
                                    &:before{
                                        color:var(--primary);
                                    }
                                    span{
                                        color: var(--primary-text);
                                        &:before{
                                            color:var(--primary)!important;
                                        }
                                    }
                                }
                                &:hover{
                                    background: var(--trasp);
                                    color:var(--primary-text);
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                            > li.poker{
                                a{
                                    background: var(--primary-btn);
                                    color:var(--primary-btn-color);
                                    &:before{
                                        color:var(--primary-btn-color)!important;
                                    }
                                    span{
                                        color: var(--primary-btn-color);
                                        &:before{
                                            color:var(--primary-btn-color)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}