
@import "skins/skin-orange-light-sass3";

//@import "colors/root/root-variables-orange-dark-sass3";


// @import "colors/root/root-variables-red-3-grad"; 
// @import "skins/skin-red-sass3-5";
@import "colors/root/root-variables-yellow-4";

// @import "colors/root/root-variables-sass3-red-5";
// @import "global/layout";
// @import "global/fontNew";
// @import "global/base-components";
// @import "global/footer";
// @import "colors/root/root-variables-green-3-bet365";

// Import all global/..sass in default-global-color
@import "colors/default-global-color";
// @import "pages/register";
// @import "pages/terms&conditions";

// @import "pages/account";

// Import all pages/..sass in pages-color
@import "colors/default-global-color";
@import "colors/pages-global-color";

// @import "pages/casino-new";
// @import "casino-fix";
// @import "casino-providers";
// @import "components/slider-casino";

// Import all pages/..sass, casino-* in pages-color
@import "colors/pages-casino-color";


// @import "components/sportMenuLive";
// @import "pages/live-betting";
// @import "pages/live-betting-calendar";
// @import "pages/live-betting-overview";

// Import all pages/..sass in pages/pages-live-betting-color
@import "colors/pages-live-betting-color";


// @import "pages/quote-fav";
// @import "components/mostPlayed";
// @import "components/sportMenuIndex";
// @import "components/sportIcons";
// @import "components/sport-filter";
// @import "components/coupon";
// @import "components/topLeague";
// @import "components/palinsesto";
// @import "components/quote-container";
// @import "components/vue-OddsMatch";
// @import "components/modal";
// @import "components/sliderIndex";
// @import "pages/print-odds";

// Import all components/..sass in pages-sport-color
@import "colors/pages-sport-color";

// @import "headers/header_new_5"; // Header new moved in header

// Import all components/..sass in pages-sport-color
@import "colors/header-new5-color";

//@import "headers/header_8"; // Header new moved in header folder
// @import "custom_temp"; // Temp custom to add custom style for skin

// New Header for new skin
// @import "headers/header-res-a";
//@import "headers/header-fix"; // Merged in header_10
// Skin colors
// @import "skins/skin-blue"
// @import "skins/skin-purple";
// @import "skins/skin-yellow";
// @import "skins/skin-green";
// @import "skins/skin-grey";
// @import "skins/skin-orange";
// @import "skins/skin-red-grey";
// @import "skins/skin-light";

// @import "custom/custom-skin-green-gold";
// @import "sass5/sass5-color"; 

// @import "custom/custom-gamesbet2-dark";
// @import "components/sportIcons-1";
// @import "custom/custom-usual-dark-blue-orange";
// @import "custom/custom_fivebet24";
// @import "custom/custom_betlumi365";
// @import "custom/custom-briobet24";


