body.casino {
    background: var(--secondary);

    /*****************
    SCROLL
    *****************/

    // * {
    //     scrollbar-color: var(--primary) var(--secondary);
    // }

    // ::-webkit-scrollbar{
    // }

    // ::-webkit-scrollbar-track {
    //     background: var(--secondary)!important;
    // }

    // ::-webkit-scrollbar-track:horizontal {
    //     background-color: var(--secondary)!important;
    // }

    // ::-webkit-scrollbar-thumb{
    //     background: var(--semi-light)!important;
    //     -webkit-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    //     -moz-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    //     box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
        
    // }
    // ::-webkit-scrollbar-thumb:horizontal{
    //     background-color:  var(--primary)!important;
    //     -webkit-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    //     -moz-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    //     box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    // }

    section{
        &.main-container-casino-res{

            .slider{
                .carousel.slide {
                    h2 {
                        color: var(--primary-text);
                    }
                    .item {
                        background: var(--semi-dark-2);
                        //background: dark secondary-box, 10%) ;
                    }
                        .carousel-control{

                            &:hover{
                                color: var(--primary);
                          }
                        }
                        .carousel-indicators{
                            li{                                 
                                background: var(--light);
                                &.active{                                       
                                    background: var(--primary);                                       
                                }
                            }
                        }
                }
            }

            .search{
                background:  var(--transparent);
                input {
                    border: 1px solid var(--semi-light);
                    background: var(--semi-light);
                    color: var(--primary-text);
                }
            }
            .casino-provider-cont{

                background: var(--semi-light);   
                background:  var(--fourth-box);                              

                .casino-provider-main{                    

                    .casino-provider{
                        background: var(--primary);
                        background-image:  var(--third4);                        
                        &.search{

                            .fa-search{
                                color: var(--primary-text);
                            }
                            input.form-control{
                                &::placeholder{
                                    color: var(--primary-text);
                                }
                                &:focus,&:active,&:visited{
                                    box-shadow: unset!important;
                                }
                                &:focus,&:active{
                                    border-color: var(--primary);
                                }
                            }

                            .fa-times{
                                &:hover{
                                    color: var(--primary);
                                }
                            }

                            &.open-search {
                                input {
                                    border: 1px solid var(--semi-light)!important;
                                    
                                }
                            }
                        }
                        .inner-cont{
                            background-color:var(--semi-dark-8);

                            &:after {
                                background: linear-gradient(to right, var(--semi-dark-5), var(--semi-dark-8));
                                //background: linear-gradient(to right,rgba(18,21,23,.86),rgba(5,11,16,.94));
                            }
                            
                            i{
                                &.casino{
                                    color: var(--primary-text);
                                    &.casino-all{
                                        span{
                                            color: var(--tertiary-text);
                                        }
                                    }
                                    
                                }
                            }
                   


                        }

                        &.active{
                            .inner-cont{
                                &:after {
                                    background-image:  var(--third4);
                                  }
                            }

                        }
                        &:hover{
                            .inner-cont{
                                &:after {
                                    filter: contrast(.7) brightness(.7);
                                  }
                            }
                        }
                    }
                    .open-provider-arrow{
                        background: var(--primary-btn);
                        color: var(--primary-btn-color);
                    }
                    
                } 
                &.open-provider{
                }               
            }
            &.left-side-provider{
                // display: flex;
                .casino-provider-cont{
                    .casino-provider-header{
                        background:  var(--primary-box);  
                        &:before{

                            background: var(--semi-light); 
                        }
                        h4.title{
                            color: var(--primary-text);
                        }
                        .search {

                            input{
                                &::placeholder{
                                    color: var(--primary-text);
                                }


                                &:focus{
                                    border-color: var(--primary);
                                }
                            }
                            i{
                                color: var(--primary-text);
                            }

                        }
                    }

                }
                .right-container{  
                    background:  var(--primary-box);//var(--secondary);                  
                }
            }


            .right-container{
                background:  var(--primary-box);
                .game-type-container{
                    border-top: 1px solid var(--semi-light);
                    background: var(--semi-light);
                    background:  var(--fourth-box);
                    .game-type-filters{
                        li{
                            border-right: 1px solid var(--semi-light);
                            &:first-child{
                                border-left: 1px solid var(--semi-light);
                            }
                            a{
                                color: var(--primary-text);
                            }
                            &.active,
                            &:hover{
                                a{
                                    color: var(--primary);
                                }
                            }
                        }
                    }
                    .search-box{
                        background: var(--trasp);
                        .search-box-top {
                            color:var(--primary-text);
                        }
                        .icon-view {
                            
                            &.casino-sorting {
                                background: var(--primary-text);
                                color: var(--dark);
                                &.active{
                                    background: var(--primary-btn);
                                    color: var(--primary-btn-color);
                                }
                            }
                            svg{
                                path{
                                    fill: var(--primary-text);
                                }
                            }
                        }
                        .search{
                            input{
                                &::placeholder{
                                    color: var(--primary-text);
                                }
                                &:focus{
                                    border-color: var(--primary);
                                }
                            }
                            i{
                                color: var(--primary-btn-color);
                                background: var(--primary-btn);
                                
                            }   
                        }
                    }

                }
                .casino-games-wrapper{
                    background:  var(--primary-box);
                    .casino-games-container{
                        ul{
                            li{
                                .game{
                                    background:  var(--primary-box);
                                    .image-cont{
                                        background: var(--secondary);
                                        .new-label{
                                            background: var(--primary-btn);
                                            color: var(--primary-btn-color);
                                        }
                                        &:after {
                                            background: var(--secondary);
                                        }
                                        .game-btns{
                                            div{
                                                a{
                                                    color: var(--primary);
                                                    text-shadow: 3px 4px 8px var(--semi-dark-7);
                                                    &:hover{
                                                        color: var(--primary-text);
                                                        span{
                                                            color: var(--primary-text);
                                                        }
                                                    }

                                                    span{
                                                        color: var(--primary);
                                                        background:  var(--transparent);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    span.title{
                                        color: var(--primary-text);
                                        //background: darke(fourth-box, 2%);

                                        background: var(--semi-dark-2);
                                    }
                                }
                                &:hover{
                                    .game{
                                        span{
                                            background: var(--primary);
                                            color: var(--dark);
                                        }

                                    }
                                }
                            }
                        }

                        .game-list-empty {
                            color: var(--primary-text);
                        }
                    }
                }
            }
        }
    }
}

/* CASINO MODAL */
body.casino{
    #login-modal{
        .modal-dialog{
            > div{
                .modal-content {               
                    background:  var(--transparent);                  
                    background-color:  var(--bg-white);                    
                    box-shadow: 2px 2px 135px var(--light);                    
        
                    .modal-header{
                        button{
                            &.close{
                                color: var(--primary);
                            }
                        }
                    }
                    .modal-body {
                        .img-cont{
                            background: var(--light);
                        }
                        .login-content{
                            .login-cont{
                                span{
                                    color: var(--dark);
                                    &.span1{
                                    }
                                    &.span2{
                                        color: var(--primary);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }   
    }

    .modal.modal-game {
        .modal-dialog{
            .modal-content {
                background-color:  var(--primary-box);                    
                box-shadow: 2px 2px 57px  var(--bg-white);
                border-color:  var(--primary-box);
                .modal-body {
                    .game-popup-container{
                        .casino-game-cont{
                            background:  var(--transparent)!important;
                            .casino-game-wrapper{
                                background:  var(--transparent)!important;
                                .controls{
                                    .btn-group{
                                        button{
                                            background:  var(--primary-box);

                                            i{
                                                color: var(--primary-text);
                                            }
                                            &:hover{
                                                i{
                                                    color: var(--primary);
                                                }
                                            }
                                        }
                                    }
                                }
                                .login-main-cont{
                                    background-image: var(--third5);

                                    .login-cont{

                                        span{
                                            color: var(--primary-text);
                                            &.span2{
                                                color: var(--primary);
                                            }                                  
                                        }
                                        .login{
                                            .form-inline{
                                                .form-group{
                                                    input{
                                                        
                                                        // background-color:  var(--transparent);
                                                        
                                                        &::placeholder{
                                                            color: var(--primary-text)    
                                                        }
                                                        
                                                    }
                                               }
                                                input{
                                                    &.btn-login{
                                                        background-color: var(--primary-btn);
                                                        color: var(--primary-btn-color);
                                                    }
                                                }
                                            }
                                           
                                        }
                                        a{
                                            &.btn-registrazione{
                                                background-color: var(--primary);
                                                color: var(--primary-btn-color);
                                            }
                                        }
                                        
                                    }
                                }
                                .pnTrasfModal-main-cont{
                                    background:  var(--third5);
                                    .pnTrasfModal-cont{
                                        span{
                                            &.span3{
                                                color: var(--primary-text);
                                            }
                                        }
                                        .button-container{
                                        }
                                    }
                                }
                                .game-container-wrapper{
                                    background:  var(--transparent)!important;
                                    &.game-4-3,&.game-16-9{                                            
                                        // background: rgba(255,255,255,0.03) !important;
                                    }
                                    .no-game{
                                        color: var(--primary-text);
                                    }

                                    .game-menu{
                                    // background: var(--semi-light);
                                    .game-search-box{
                                        &:before {
                                            color: var(--primary-text);
                                            }

                                            input#gameSearch {
                                            border: 1px solid var(--semi-light);
                                            background: var(--semi-light);
                                            color: var(--primary-text);
                                            }

                                            .slimScrollDiv{
                                            ul{
                                                background:  var(--dropdown);
                                                li{
                                                    &:hover{
                                                        a{
                                                            background: var(--semi-light);
                                                            border-color: var(--semi-light);
                                                            
                                                        }
                                                    }
                                                    a{
                                                        color: var(--primary-text);
                                                        .image{   
                                                            // .labeltext {
                                                            //     color:var(--light);
                                                            // }                                                          
                                                        }
                                                        &.ui-state-focus{
                                                            background: var(--semi-light);
                                                            border-color: var(--semi-light);
                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }
                                    .game-info{
                                        span{
                                            &.game-title{
                                                background:  var(--header-grad);
                                                color: var(--tertiary-text);
                                            }
                                        }
                                        .game-thumb {

                                        }
                                        .add-pref{
                                            background: var(--primary-btn);
                                            span{
                                                color: var(--primary-btn-color);
                                                &:after {
                                                    color: var(--primary-btn-color);
                                                    }
                                            }
                                        }
                                    }
                                    .balances{
                                        color: var(--primary-text);
                                        ul{                                               
                                            background: var(--tertiary-box);
                                            li{
                                                span{
                                                    &.icon{
                                                        color: var(--primary-text);
                                                        i{
                                                            &.fa-arrow-left{
                                                                color: red;
                                                            }
                                                        }
                                                    }
                                                    &.amount{
                                                        color: var(--primary);
                                                    }
                                                }
                                                &.title{
                                                    background:  var(--header-grad);
                                                    color: var(--tertiary-text);
                                                }
                                            }
                                        }
                                    }
                                    .casinotransfer-container{
                                        button{
                                            span{
                                            }
                                        }
                                    }
                                    .panel-preferiti{
                                        background: var(--tertiary-box);
                                        .panel-heading{
                                            background:  var(--header-grad);
                                            color: var(--tertiary-text);
                                        }
                                        .panel-body{
                                            color: var(--primary-text);
                                            ul{
                                                li{
                                                    .game-back{
                                                        //background: rgba(secondary,0.7);
                                                        background: var(--semi-dark-7);
                                                        .btn-play{
                                                            color: var(--tertiary-text);
                                                            background:  var(--primary-box);
                                                        }
                                                        .preferiti{
                                                            color: var(--primary);
                                                        }

                                                    }
                                                }
                                            }                                                
                                        }
                                        
                                    }
                                    }

                                    & .middle-box {
                                        background:  var(--semi-dark-8)!important;                                        .loading-game{

                                        }
                                    }
                                    #pngamemenu.game-menu{
                                        .game-info{
                                            .game-title{
                                               // background: rgba(light), 0.12);
                                                background: var(--semi-light-12);
                                            }

                                        }
                                        .balances {
                                            // background: 3e3d3d;
                                        }
                                        .panel.panel-preferiti{
                                            // background: 3e3d3d!important;
                                            .panel-heading{
                                                // background: rgba(255,255,255,0.12);
                                            }

                                        }
                                        .casinotransfer-container{
                                            button{

                                            }
                                        }
                                    }

                                }
                                .game-info-cont{
                                    .search-box{
                                        .search{

                                            input{
                                                &::placeholder{
                                                    color: var(--primary-text);
                                                }

                                                border: 1px solid var(--semi-light)!important;
                                                background: var(--semi-light)!important;
                                                color: var(--primary-text)!important;

                                                &:focus{
                                                    border-color: var(--primary);
                                                }
                                            }
                                            i {
                                                color: var(--primary-btn-color);
                                                background: var(--primary-btn);
                                            }   
                                        }
                                    }

                                    .game-info-thumb{
                                        background: var(--semi-light);
                                        .game-title{                                            
                                            background:  var(--panel-header);
                                        }
                                        .add-pref.btn{
                                            color: var(--primary-btn-color);
                                            background: var(--primary-btn);
                                            &:hover{
                                                box-shadow: unset!important;
                                                //background: dark(primary, 7%);
                                                background: var(--semi-dark-7);
                                            }
                                        }
                                    }
                                    
                                    .balances{
                                        background: var(--semi-light);
                                        
                                        ul{
                                            li{
                                                border-bottom: 1px solid var(--semi-light);
                                                &.title{
                                                    background:  var(--panel-header);
                                                }
                                                span{
                                                    &.icon{
                                                        color: var(--light);
                                                        i{
                                                            &.fa-refresh{
                                                                color: var(--primary);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }  
                                    .casinotransfer-button{
                                        button{
                                            color: var(--primary-btn-color);
                                            background: var(--primary-btn);
  
                                            &:hover{
                                                box-shadow: unset!important;
                                                //background: dark primary, 7%);
                                                background: var(--semi-dark-7);
                                            }
                                        }
                                    }             
                                }
                            }
                        }
                    }
                }
                .modal-btns {
                    background-color: var(--secondary);
                    a {
                        color: var(--primary-text);

                        i {
                            text-shadow: 1px 1px 6px var(--semi-dark-7),0 0 1em var(--semi-dark-7),0 0 .2em var(--semi-dark-7);
                          }
                      }
                }
            }
        }        
    }
    
    
    .section-wrapper{
        #casino-game-live.modal{
            background:  var(--transparent)!important;
            .modal-dialog{                
                background-color:  var(--transparent)!important;
                > div{
                    background-color:  var(--transparent)!important;
                    .modal-content {
                        .modal-body {
                            background-color:  var(--transparent)!important;
                            .game-content {
                                .casino-game-cont{
                                    .casino-game-wrapper{
                                        background:  var(--transparent)!important;
                                        .game-container-wrapper{
                                            background:  var(--transparent)!important;
                                        }
                                    }
                                }
                            }
                        }
                        .modal-btns {
                            a.btn {
                                background:  var(--transparent)!important;
                                &:hover{
                                    box-shadow: unset!important;
                                }
                            }
                        }
                    }
                }
            }        
        }
    }

    &.skin-color--green{
        &.skin--light{
            .header-logo{
                a{
                    img{
                        filter: invert(1) hue-rotate(235deg) brightness(1.5);
                    }
                }
            }
        }
        .header-logo{
            a{
                img{
                    filter: hue-rotate(45deg) ;
                }
            }
        }
    }


    #modal-game.modal-game{
        .modal-dialog{
            .modal-content{
                .modal-body{
                    background:  var(--transparent);
                    .game-popup-container{
                        .casino-game-cont{
                            .casino-game-wrapper{
                                .game-container-wrapper{
                                    &.middle-box {
                                        background:  var(--semi-dark-8)!important;
                                        .loading-game{
                                            background:  var(--semi-dark-7);
                                        }
                                    }
                                    #pngamemenu.game-menu{
                                        .game-info{
                                            // background: var(--semi-light);
                                            // background: 3e3d3d;
                                            .game-title{
                                                //background: rgba(light, 0.12);
                                                background: var(--semi-light-12);
                                            }
                                        }
                                        .balances {
                                            // background: 3e3d3d;
                                        }
                                        .panel.panel-preferiti{
                                            // background: 3e3d3d!important;
                                            .panel-heading{
                                                // background: rgba(255,255,255,0.12);

                                            }
                                        }
 
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}


.chevron:before,
.chevron:after {
    background: var(--primary-text);
}

@media screen and (max-width: 1440px){
    body.casino {
        section.main-container-casino-res {
            .casino-provider-cont {
                .casino-provider-main {
                    .casino-provider.search.open-search {
                        input {
                            border: 1px solid var(--semi-light)!important;
                        }
                    }
                }
            }
        }
    }
}