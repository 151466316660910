
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--semi-dark-3);
}

* {
    scrollbar-color: var(--primary) var(--secondary);

    ::-webkit-scrollbar-thumb{
        background: var(--primary)!important;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background-color: var(--primary)!important;
    }

    ::-webkit-scrollbar-track {
        background:  var(--secondary)!important;
    }

    ::-webkit-scrollbar-track:horizontal {
        background:  var(--secondary)!important;
    }
    
}

::-webkit-scrollbar-thumb{
    background: var(--primary)!important;
}
::-webkit-scrollbar-thumb:horizontal{
    background-color: var(--primary)!important;
}

::-webkit-scrollbar-track {
    background:  var(--secondary)!important;
}

::-webkit-scrollbar-thumb{
    background: var(--primary)!important;
    -webkit-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    -moz-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    
}
::-webkit-scrollbar-thumb:horizontal{
    background-color: var(--primary)!important;
    -webkit-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    -moz-box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
    box-shadow: inset 0px 0px 0px 1px var(--semi-dark-7);
}

::-moz-selection { /* Code for Firefox */
    color: var(--light)!important;
    background: var(--dark)!important;
  }
  
  ::selection {
    color: var(--light)!important;
    background: var(--dark)!important;
  }

body {
    //background: var(--light);
    background: var(--primary-box);


    &.skin--dark {
        background: var(--dark);
    }


    ::-moz-selection { /* Code for Firefox */
        color: var(--primary-text);
        background: var(--primary);
      }
      
      ::selection {
        color: var(--primary-text);
        background: var(--primary);
      }




    a {
        color: var(--primary-text);
    }


    .nav > li > a:hover, 
    .nav > li > a:focus {
        background-color: var(--trasp);
    }


    /*****************
    BUTTON
    *****************/

    button,
    .btn{
        color: var(--primary-btn-color);
        background: var(--primary-btn);
  
        &:focus,
        &.focus,
        &:hover{
          color: var(--primary-btn-color);
          background: var(--primary-btn);
        }
        &.close{
            background: transparent;
        }
    }

    ol,ul{

    }

    .dropdown-menu {
        background-color: var(--tertiary-box);
        color: var(--primary-text);
    }


    /*****************
    FORM INPUT 
    *****************/
    // select#SelectDaysMenu,
    .form-control,
    input[type="password"],
    input[type="number"],
    input[type="text"] {
        &:active,
        &:focus {
            border-color: var(--primary);
        }
        border: 1px solid var(--primary-input);
        background: var(--primary-input);
        color: var(--primary-text);
    }

    .form-control::-webkit-input-placeholder {
        color: var(--primary-text);
    }


    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
      background-color: var(--semi-dark);
      color: var(--primary-text);
    }
    // select#SelectDaysMenu option,
    select option {
    //   background-color:var(--secondary-input);
        background-color: var(--light);
        padding: 20px;
        color: var(--dark);
        &:hover{
            // background-color: light dropdown, 5%);
            background-color: var(--semi-light);
            box-shadow: 0 0 10px 100px var(--tertiary) inset;
            //box-shadow: 0 0 10px 100px #1882A8 inset;
        }
    }
    // select#SelectDaysMenu,
    select.form-control{
      color: var(--primary-text);  
      background-color: var(--semi-light);
    &:focus {
         border-color: var(--primary);
      }     
    }

    .radio-container,
    .checkbox-container,
    .checkbox{
        label{
            color: var(--primary-text);
            input[type="radio"],
            input[type="checkbox"]{

                &:after{
                    color: var(--secondary);
                }
                &:checked::after {
                    color: var(--primary) !important;
                }
            }
        }
    }
    input[type="radio"],
    input[type="checkbox"]{

        &:after{
            color: var(--secondary);
        }
        &:checked::after {
            color: var(--primary) !important;
        }
    }

    .radio-container{

    }




    /*****************
    WRAPPER BODY PAGES 
    *****************/

    .section-wrapper {

    }

    .loading_wrp {
        color: var(--light);

    }

    footer{
        background-color: var(--secondary); //primary-box;
        // box-shadow: 0 -19px 20px rgba(0,0,0,.7);
        .footer-top{
            .container-fluid{
                .casino-providers-main{
                    a{
                        background-image: url(../../img/casino/provider/providers-light.png);
                        background-size: auto;
                    }
                }
                > .row,
                .row.text,
                .row.pay,
                .row.menu-footer-new{
                    color: var(--primary-text);
                    .col-md-2{
                        .page-header{
                            color: var(--primary);
                        }
                        ul{
                            li{
                                a{
                                    color: var(--primary-text);
                                    &:hover{

                                        color: var(--primary-text)
                                    }
                                }
                            }
                        }
                    }
                }
                .row.text{

                    .col-md-10{
                        > p {
                        }
                        .panel-license {
                            border-top: 1px solid var(--semi-light);
                            border-bottom: 1px solid var(--semi-light);
                        }
                        ul{
                            li{
                                a{
                                    color: var(--primary-text);

                                }
                            }    
                        }
                    }
                }
            }
        }
        
    }

    #loading-modal-new{
        background: var(--semi-dark-8);
        p{
            i{
                color: var(--light);
            }
        }
        
    }
}


#vue-Slider-index{
    .carousel{
        .item{

            a{
                &::before {
                    filter: hue-rotate(var(--slider-color));
                }
            }
        }
        .carousel-control{
            span{
            }
        }
    }
}

.banner.banner--overlay {
    a {
    //   &::before {
    //     filter: hue-rotate(var(--slider-color));
    //   }
    }
    &.right,
    &.left {
      a {
        // &::before {
        //     filter: hue-rotate(var(--slider-color));
        // }
      }
    }
    &.top,
    &.bottom {
      a {
        &::before {
            filter: hue-rotate(var(--slider-color));
        }
      }
    }
}


@mixin heading-small{
    background: var(--header-grad);
    color: var(--heading-color);
    .title,
    span, 
    h4,
    h3{
      color: var(--heading-color);
    }
  }
  
  @mixin heading-big{
    background: var(--header-grad);
    color: var(--heading-color);
    .title,
    span, 
    h4,
    h3{
      color: var(--heading-color);
    }
  }
  
  @mixin live-heading{
    background: var(--fourth);
    color: var(--live-heading-color);
  }