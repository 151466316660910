:root {
        
    --header-color : #1b1b1b;
    --header-top : #2a2c2f;
    --header-menu : #2a2c2f;
    
    --header-text-primary: #ffffff;
    --header-text-secondary: #ffffff;

    --primary-box: #1b1b1b;
    --secondary-box : #585858;
    --tertiary-box: #2a2c2f; //#323232;
    --fourth-box: #303131; //#585a5b;

    --primary-btn: #f4c200;
    --secondary-btn : #414345;

    --live-selected: #868686;

    --primary: #f4c200;
    --secondary: #3d4044; //#282728; //#3c3c3c; //#2a2c2f;
    --tertiary:#414141;
    --fourth: #3c3c3c; //#4e4e4e;
    
    --primary-text: #ffffff;
    --secondary-text: #fff; //#000;
    --tertiary-text: #fff;
    --fourth-text: #777;

    --primary-btn-color:  #111;
    --secondary-btn-color: #ffffff;

    --heading-color: #f4c200;
    --live-heading-color: #c1c1c1;
    
    //--text-light: #000;
    //--text-dark: #FFF;
    
    //--custom-bg: #212334;
    --custom-red: #fb3754;
    --custom-green: #0b840b;
    --dropdown: #181617;

    --header-grad : #35373a; //var(--tertiary);

    //gradients
    //--third: linear-gradient(to bottom,saturate(lighten($primary, 5), 0),adjust_hue(desaturate(darken($primary, 22), 42), 339));
    //--third2: linear-gradient(45deg, #15411a 0%, #0e9649 25%, rgb(28, 102, 38) 100%);
    //--third3:  linear-gradient(to right, rgba(lighten($secondary, 2%), 0.9), rgba($secondary, 0.9));
    //--third4: linear-gradient(to bottom,#48a432,#94b143);
    //--third5: linear-gradient(45deg, #151841 20%, #008a2d 60%, #007b28 80%,#024f1b 100%);
    
    --third4: linear-gradient(180deg,var(--primary), #836c13);
    --third5: linear-gradient(45deg,var(--dropdown) 20%, var(--secondary) 60%, var(--tertiary) 80%, var(--fourth) 100%,);
    // --text-custom: green;
    //--bg-white: #fff;
    //--bg-black: #000;

    --primary-input : rgba(255, 255, 255, 0.05);
    //--secondary-input : #15191C;

    --light : #ffffff;
    --dark : #000000;
    --trasp : transparent;
    
    --semi-dark : rgba(0,0,0,0.05);
    --semi-light : rgba(255,255,255,0.05);
    --semi-light-2 : rgba(255,255,255,0.2);
    --semi-light-3 : rgba(255,255,255,0.3);
    --semi-light-8 : rgba(255,255,255,0.8);

    --semi-light-12 : rgba(255,255,255,0.12);

    --semi-dark-1 : rgba(0,0,0,0.1);
    --semi-dark-2 : rgba(0,0,0,0.2);
    --semi-dark-3 : rgba(0,0,0,0.3);
    --semi-dark-5 : rgba(0,0,0,0.5);
    --semi-dark-7 : rgba(0,0,0,0.7);
    --semi-dark-8 : rgba(0,0,0,0.8);
    

    
    --slider-color : 298deg;

    --header-top-icon-color : #f4c200;
    --header-top-text-color : #ffffff;

  }
