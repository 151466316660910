
/*************************/
/**** SPORT MENU LIVE ****/
/*************************/


body.live{
    .wrapper-live{
        section.main-content{
            .wrapper{
                .live-col-cont{
                    .col-left{
                        #vue-MenuSport{
                            .search-box-live{
                                background: var(--tertiary-box);
                                .form-group{
                                    input{
                                        &#txtSearch{
                                            background: var(--semi-light);
                                            color: var(--primary-text);
                                        }
                                        &.btn{
                                            color: var(--tertiary-text);
                                        }
                                    }
                                    &:after {
                                        // color: var(--tertiary-text);
                                      }
                                }

                                .filter-streaming{
                                    .onoffswitch4 {
                                        .onoffswitch4-label {
                                            .onoffswitch4-inner {
                                                &:before {
                                                    background-color: var(--primary-btn);
                                                    color: var(--primary-btn-color);
                                                }
                                                &:after {
                                                    background-color: var(--secondary);
                                                    color: var(--primary-text);
                                                }
                                            }
                                            .onoffswitch4-switch {
                                                //background secondary, 15%);
                                                background: var(--semi-light-2);
                                            }
                                        }

                                    }



                                }
                            }
                            > .left-nav.panel{
                                background-color: var(--tertiary-box)!important;
                                color: var(--primary-text);
                                .left-nav.panel{
                                    background-color: var(--tertiary-box)!important;
                                    color: var(--primary-text);
                                }
                                .menu-live.menulive4v,
                                .menu-live.menulive3v{
                                    .title.panel-heading{
                                        @include heading-small;
                                        color: var(--tertiary-text);
                                    }
                                    .menu-item{
                                        &.preferiti{
                                            ul.pref{
                                                li.list-group-item.has-sub{
                                                    background: var(--primary-box);
                                                    cursor: pointer;
                                                    &.selected-menu{
                                                        > div{
                                                            background: var(--live-selected);
                                                            color: var(--tertiary-text);
                                                        }
                                                    }
                                                    > div{
                                                        background: var(--secondary);
                                                        .score-cont{
                                                            background: var(--tertiary-box);
                                                            .score{
                                                                color: var(--primary-text);
                                                            }
                                                        }
                                                        .set{
                                                            .bk-grn{
                                                                background: var(--custom-green);
                                                                color: var(--tertiary-text);
                                                            }
                                                            .bk-red{
                                                                background: var(--custom-red);
                                                                color: var(--tertiary-text);
                                                            }
                                                        }
                                                        .preferiti{
                                                            color: var(--primary);
                                                        }
                                                        .live-streaming{
                                                            color: var(--primary);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        a{
                                            background: var(--header-grad);
                                            color: var(--tertiary-text);
                                            // box-shadow: inset 0px -6px 0px -5px var(--tertiary-box);
                                            &:focus,
                                            &:hover{
                                                color: var(--tertiary-text);
                                            }
                                        }
                                        > div{
                                            .level-2{
                                                background: var(--tertiary-box);
                                                .nazione-title{
                                                    background: var(--primary-box);
                                                    cursor: pointer;
                                                }
                                                > div{
                                                    ul{
                                                        li.list-group-item.has-sub{
                                                            background: var(--secondary);
                                                            cursor: pointer;
                                                            &.selected-menu{
                                                                background: var(--live-selected);
                                                                color: var(--tertiary-text);
                                                            }

                                                            > div{
                                                                .score-cont{
                                                                    background: var(--tertiary-box);
                                                                    .score{
                                                                        color: var(--primary-text);
                                                                    }
                                                                }
                                                                .set{
                                                                    .bk-grn{
                                                                        background: var(--custom-green);
                                                                        color: var(--tertiary-text);
                                                                    }
                                                                    .bk-red{
                                                                        background: var(--custom-red);
                                                                        color: var(--tertiary-text);
                                                                    }
                                                                }
                                                                .preferiti{
                                                                    color: var(--primary);
                                                                }
                                                                .live-streaming{
                                                                    color: var(--primary);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                
                            }
                        }
                    }
                }
            }
        }
    }
}



/**********************/
/**** LIVE BETTING ****/
/**********************/

body.live{
    .wrapper-live{
        section.main-content{
            .wrapper{
                .live-col-cont{
                    .top-menu-live{
                        ul{
                            background: var(--tertiary-box);
                            li{
                                a{
                                    color: var(--primary-text);
                                    &:after {
                                        background: var(--semi-light);
                                    }
                                    &.active,
                                    &:hover{
                                        color: var(--primary);
                                        &:before{
                                            color: var(--primary)!important;
                                        }

                                    }

                                    &:before{
                                        color: var(--semi-light-3);
                                    }
                                }
                                &.header-close{
                                    i{
                                        color: var(--primary-text);
                                        &:hover{
                                            color: var(--primary);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .col-main{
                        #divcenter{
                            .container-quote{
                                background: var(--tertiary-box);
                                .iframe-content{
                                    .menu-center{
                                        @include heading-big;
                                        .top-settings{
                                            a{
                                                color: var(--semi-light-3);
                                                &:hover{
                                                    color: var(--primary-text);
                                                }
                                            }
                                        }
                                    }
                                    button#btnEnlargeFrame{
                                        background: var(--trasp);
                                        span{
                                            color: var(--semi-light-3);
                                            &:hover{
                                                color: var(--primary-text);
                                            }
                                        }
                                    }
                                }
                                #vue-Liveplay{
                                    .container-fluid.quote-bottom{
                                        .nav-markets-groups{
                                            background: var(--secondary);
                                            .frame{
                                                ul.clearfix{
                                                    li{
                                                        a{
                                                            color: var(--primary-text);
                                                        }
                                                        &.active{
                                                            a{
                                                                background: var(--semi-dark-3);
                                                                box-shadow: 0 -2px 0 var(--primary) inset;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            button.btn{
                                                background: var(--primary-box);
                                                color: var(--primary-text);
                                            }
                                        }
                                        .scroll-wrapper.tab-content{
                                                #colcol.tab-content{
                                                    .tab-pane{
                                                        .row{
                                                            .tab-qt.panel{
                                                                background: var(--tertiary-box);
                                                                box-shadow: inset 0 0 0 1px var(--fourth);
                                                                .int-quote.panel-heading{
                                                                    @include live-heading;
                                                                }
                                                                .panel-body{
                                                                    .row{
                                                                        .box-quota{
                                                                            .qt-container{
                                                                                background: var(--primary-box);
                                                                                color: var(--primary-text);
                                                                                &:hover{
                                                                                    background: var(--primary-btn);
                                                                                    color: var(--primary-btn-color);
                                                                                }
                                                                            }

                                                                            &.spread{
                                                                                .qt-container{
                                                                                    background: var(--primary-btn);
                                                                                    color: var(--primary-btn-color);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                        }
                                        .quote-close{
                                            color: var(--primary-text);
                                        }
                                    }
                                }
                            }
                        }
                    }

                }

                .col-right{
                    .col-right-live {
                        #framesoccright {
                            #tabs-right {
								.resize-btn {
                                    background: var(--primary-btn);
                                    i {
                                        color: var(--primary-btn-color);
                                    }
								}
							}
                            ul.nav.nav-tabs {
                                background: var(--secondary);
                                border-bottom-color: var(--secondary);
                                li {
                                    background: var(--trasp);
                                    a {
                                        color: var(--primary-text);
                                        background: var(--secondary);
                                    }
                                    &.active{
                                        a{
                                            background: var(--semi-dark-3);
                                            box-shadow: 0 -2px 0 var(--primary) inset;
                                        }
                                    }
                                }
                            }
                            ul.nav.nav-tabs.two {
                                li {
                                    background: var(--trasp);
                                }
                            }
                            ul.nav.nav-tabs.three {
                                li {
                                    background: var(--trasp);
                                }
                            }
                            .tab-content{
                                background: var(--tertiary-box);
                            }
                        }
                    }
                    #rightdiv {
						#framesoccright {
							.tab-content {
								#live-streaming {
									#framestreaming-right-error {
										color: var(--tertiary-text);
									}
								}
							}
						}
					}
                }
            }

            &.livestreaming{
                .wrapper {
                    .col-right {
                        .col-right-live {
                            #framesoccright {
                                ul.nav.nav-tabs {
                                    li {
                                        background: var(--trasp);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}


/*******************************/
/**** LIVE BETTING OVERVIEW ****/
/*******************************/

body.live{
    .wrapper-live{
        section.main-content{
            .wrapper{
                .live-col-cont{
                    .live-overview{
                        .panel-group{
                            .panel{
                                background: var(--trasp);
                                color: var(--primary-text);
                                .panel-heading{
                                    @include heading-big;
                                    color: var(--tertiary-text);
                                    background: linear-gradient(90deg, var(--tertiary), var(--secondary) 100%);
                                    border-bottom-color: var(--secondary);
                                }
                                .panel-collapse{
                                    background: var(--trasp);
                                    table.table{
                                        border-color: var(--semi-dark-2);
                                        tbody{
                                            tr{
                                                border: unset;
                                                &:hover{
                                                    //background: dark (secondary, 3);
                                                    background: var(--semi-dark-3);
                                                }
                                                td{
                                                    border-color: var(--semi-dark-2);
                                                    &.dark{
                                                        .no-markets{
                                                            color: var(--trasp);
                                                            &:before{
                                                                color: var(--primary-text);
                                                            }
                                                        }

                                                        &.time{
                                                            border-right-color:var(--semi-light-12);
                                                            &.info-td-width{
                                                                span{
                                                                    &.torneo{
                                                                        background: var(--secondary);
                                                                    }
                                                                }
                                                                > div.dark{
                                                                    a{
                                                                        color: var(--primary-text);
                                                                        &:hover{
                                                                            color: var(--tertiary-text);
                                                                        }
                                                                        span{
                                                                            &.score{
                                                                                background: var(--primary-btn);
                                                                                color: var(--primary-btn-color);
                                                                            }
                                                                        }
                                                                    }
                                                                    .live-streaming{
                                                                        color: var(--primary);
                                                                    }
                                                                    .campo{
                                                                        &.selected{
                                                                            box-shadow: 0 0 3px 1px var(--primary);
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        .dark.titles{
                                                            .row{
                                                                .market-title{
                                                                    > span{
                                                                        background: var(--secondary);
                                                                        color: var(--fourth-text);
                                                                        &:after{
                                                                            background: var(--semi-light);
                                                                        }
                                                                        &:first-child{
                                                                            &:before{
                                                                                background: var(--semi-light);
                                                                            }
                                                                        }
                                                                    }
                                                                    .quota-cont{
                                                                        background: var(--trasp);
                                                                        color: var(--primary-text);
                                                                        .qt-container{
                                                                            box-shadow: inset 0 0 0 1px var(--semi-light-12);
                                                                            &:hover{
                                                                                background: var(--primary-btn);
                                                                                color: var(--primary-btn-color);
                                                                            }

                                                                            &.hnd{
                                                                                color: var(--primary);
                                                                                &:hover{
                                                                                    background: var(--trasp);
                                                                                    color: var(--primary);
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    &:nth-child(2){
                                                        &:before{
                                                            background: var(--secondary);
                                                        }
                                                    }
                                                    &:last-child{
                                                        a{
                                                            background: var(--primary-btn);
                                                            &:after{
                                                                color: var(--primary-btn-color);
                                                            }

                                                            &:hover{
                                                                //background: dark (primary, 6);
                                                                background: var(--semi-dark-5);                                                                
                                                                &:after{
                                                                    color: var(--tertiary-text);
                                                                }
                                                            }
                                                        }
                                                        &:before{
                                                            background: var(--secondary);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************/
/**** LIVE BETTING CALENDAR ****/
/*******************************/

body.live{
    .wrapper-live{
        section.main-content{
            .wrapper{
                .live-col-cont{

                    .live-calendario{
                        .panel-body{
                            background: var(--tertiary-box);
                            ul.nav.nav-tabs{
                                background: var(--secondary);
                                li{
                                    a{
                                        color: var(--primary-text);

                                        &:hover{
                                            color: var(--primary-text);
                                            background: var(--trasp);
                                        }
                                    }
                                    &.active{
                                        a{
                                            background: var(--semi-dark-3);
                                            box-shadow: 0 -2px 0 var(--primary) inset;
                                        }
                                    }
                                }
                            }

                            .tab-content{
                                table.table{
                                    tbody{
                                        tr{
                                            td{
                                                color: var(--primary-text);
                                                &.sport{
                                                    color: var(--primary-text);
                                                    background: var(--secondary);
                                                }
                                            }
                                            &:hover{
                                                //background: dark (secondary, 3);
                                                background: var(--semi-dark-3);
                                                td{
                                                    &.sport{
                                                        color: var(--primary);
                                                    }
                                                    &.orario {
                                                        color: var(--primary)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }

            }


        }
    }
}