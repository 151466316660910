body{
    &.registrazione{
        .wrapper-boxed{
            section#main{
                color: var(--primary-text);            
                background: var(--tertiary-box);
                .registrazione-page{
                    .page-title{
                        @include heading-small;
                        background: var(--trasp);
                        h3{
                            color: var(--primary);
                        }
                    }

                    
                    .conferme-bottom,
                    .dati-personali,
                    .dati-account{    
                        background: var(--secondary);      
                        .title{
                            @include heading-small;
                            background: var(--secondary);
                        }
                        > .row{
                            .col-sm-6{

                                .form-horizontal-reg{
                                    .form-group{
                                        .col-sm-8{
                                            width: calc(100% - 90px);
                                            select{
                                                color: var(--primary-text);
                                                option{
                                                    background: var(--light);
                                                    color:  var(--dark);
                                                }
                                            }
                                        } 
                                    }
                                }
    
                            }
                        }
                    }

                    .conferme-bottom{
                        .row{
                            .col-sm-9{
                                .checkbox{
                                    label{
                                        input[type="checkbox"]{
                                            &:after{
                                                color: var(--tertiary-box);
                                            }
                                            &:checked::after {
                                                color: var(--primary)!important;
                                              }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .iti.iti--allow-dropdown{
                        ul.iti__country-list {
                            background: var(--dropdown);
                        }
                    }
                    
                }
            }
        }
    

    }

    &.areariservata{
        section#main{
            color: var(--primary-text);            
            // background: var(--tertiary-box);
            .conferma-registrazione{
                .attivazione-container,
                .registrazione-container{
                    background: var(--tertiary-box);
                    .page-title{
                        @include heading-small;
                        background: var(--trasp)!important;
                        h4{
                            color: var(--primary-text)!important;
                        }
                    }
                    .content{
                        background: var(--secondary);
                    }
                }
            }
        }
        // Modal
        .modal-dialog {
            
            .modal-content {
                background:  var(--primary-box);
                color: var(--tertiary-text);
                .modal-header {
                    button.close {
                        color: var(--trasp);
                        &:after,
                        &:before{
                            background:  var(--custom-red);
                        }
                    }
                }

                .modal-body{
                    h4{
                        @include heading-small;
                        background: var(--tertiary-box);
                        color: var(--primary-text);
                    }
                    
                }

                .modal-footer {
                    .col-sm-4 {
                        .printer {
                            background: var(--primary-btn);
                            color: var(--primary-btn-color);
                        }
                        &#buttonannpop {
                            .btn-annulla.button-cerca-pop2{
                                background: var(--secondary);
                                color:  var(--custom-red);
                            }
                        }
                        &#pnCashout{
                            .ft-amount-container{
                                background:  var(--fourth);
                            }
                        }
                    }
                }

            }

            &.popup-scom{
                .modal-content{
                    .modal-body{
                        background: var(--trasp);
                    }
                }
            }
        }

        .modal.termini{
            .modal-body{                
                background-color: var(--trasp); 
                .content-chisiamo{
                    .panel.panel-termin{
                        color: var(--primary-text);
                        background-color: var(--secondary);
                    }
                }   
            }
            
        }

        // Table
        .table-responsive {
            border: 0px solid var(--trasp);
        }
        table:not(.ui-datepicker-calendar),
        .table-bordered {
            // background:  var(--primary-box;
            color: var(--primary-text);
            border: 1px solid var(--trasp);
            background: var(--secondary);
            thead,
            tbody,
            tfoot {
                tr{
                    th {
                        color: var(--primary-text);
                        background:  var(--fourth);                        
                        border: 1px solid  var(--fourth)!important;
                    }
                    
                    td {
                        border: 1px solid var(--semi-light);
                        &.tot-giocato{
                            color: green;
                        }
                        &.tot-vinto{
                            color: $custom-red;
                        }
                        &.rosso-bk{
                            color: $custom-red;
                        }
                        a {
                            color: var(--primary);
                        }
                        .blu{
                            color:$blue;
                        }
                        .rosso{
                            color:$rosso;
                        }
                        .arancione{
                            color:$arancione;
                        }
                        .giallo{
                            color:$yellow;
                        }
                        .verde-vin{
                            color:$verde-vin;
                        }
                        .verde-pag {
                            color: $verde-pag;
                        } 
                        .esito-quadro{
                            .blu{
                                background:$blue;
                            }
                            .rosso{
                                background:$rosso;
                            }
                            .arancione{
                                background:$arancione;
                            }
                            .giallo{
                                background:$yellow;
                            }
                            .verde-vin{
                                background:$verde-vin;
                            }
                            .verde-pag {
                                background: $verde-pag;
                            }                           
                            > div{
                                &.verde-pag{
                                    background: $verde-pag;
                                }
                                &.rosso{
                                    background: $rosso;
                                }
                                &.blu{
                                    background: $blue;
                                }
                                &.arancione{
                                    background: $arancione;
                                }
                                
                            }
                        }
                    }
                }
            }
        }

        .pagination{
            li{
                &.disabled{
                    a,
                    a:hover,
                    a:focus,
                    span,
                    span:hover,
                    span:focus{
                        background: var(--secondary);
                        border-color: var(--secondary);
                        color: var(--primary-text);
                    }
                }
                &.active{
                    a{
                        background: var(--primary-btn);
                        border-color: var(--primary-btn);
                        color: var(--primary-btn-color);
                    }
                }
                a{
                    background: var(--secondary);
                    border-color: var(--secondary);
                    color: var(--primary-text);
                }
            }
        }
        

        .wrapper-boxed{
            section#main{
                .container-fluid {
                    background: var(--trasp);
                    &.regolamento-page{              
                        background: var(--tertiary-box);
                        .page-title{
                            @include heading-small;
                            background: var(--secondary);
                            h4{
                                color: var(--primary-text);
                            }
                        }
                
                        .row{
                            .col-sm-12{
                                .panel{
                                    background: var(--trasp);
                                    .panel-heading{
                                        @include heading-small;
                                    }
                                    .panel-body{
                                        color: var(--primary-text);
                                        background: var(--secondary);
                                    }
                                }
                            }
                        }
                    }

                    &.dettaglio-commissioni{
                        .page-title{
                            background: var(--tertiary-box);
                            h4{
                                color: var(--primary);
                                span.dettagli{
                                    color: var(--primary-text);
                                }
                            }
                        }
                        .table{
                            tr{
                                &:hover{
                                    background: var(--semi-dark-2 ); //rgba(0,0,0,0.15);
                                }
                            }
                            &.table-totali{
                                //background: darke (fourth, 5%);
                                background: var(--semi-dark-5);
                                tr{
                                    &:hover{
                                        background: var(--semi-dark-2); //rgba(0,0,0,0.15);
                                    }
                                }
                            }
                        }
    
    
                    }

                    .page-title {
                        @include heading-big;
                        background: var(--secondary);
                        h4 {
                            color: var(--primary-text);

                        }
                    }

                    > .row {
                        .form-group {
                            .control-label {
                                color: var(--primary-text);
                            }
                        }
        
                        .legenda-bottom {
                            border-top: 1px solid var(--semi-light);
                            border-bottom: 1px solid var(--semi-light);
                            color: var(--primary-text);
                        }
                        .box-areariservata,
                        .lista-utenti {
                
                            .opzioni-ricerca {
                                background: var(--tertiary-box);
                                h5 {
                                    span {
                                        color: var(--primary);
                                    }
                                }
    
                            }

                            #UpdatePanel1 {
                                background: var(--tertiary-box);
                            }
                        }
    
                        .report,
                        .tab-ric-mov,
                        .tab-ric {
                
                            .opzioni-ricerca {
                                h5 {
                                    span {
                                        color: var(--primary);
                                    }
                                }
    
                            }
                        }
        
        
                        .side-nav-backoffice.sidebar {
                            background: var(--tertiary-box); //var(--secondary);
                            .page-header {
                                @include heading-big;
                                background: var(--secondary);
                                span{                                    
                                    color: var(--primary-text)!important;
                                }
                            }
                            .sub-page-header {
                                color: var(--primary);
                            }
        
                            ul.nav {
                                li {        
                                    a {
                                        color: var(--primary-text);

                                        &::after {
                                            background: var(--semi-light);
                                        }

        
                                        &.active,
                                        &:hover {
                                            background: var(--secondary);
                                            // color: var(--primary);
                                        }
                                    }
        
                                        
                                }
                            }
                        }
                    }
                    &#bonusPage{
                        .main-cont.full{
                            background: var(--trasp);
                            .bonus{
                                .bonus-container{
                                    background: var(--tertiary-box);
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }

                }

                // Main content section

                .main-cont,
                .main-cont.full {
                    background:var(--tertiary-box);// var(--secondary);
                    color: var(--primary-text);

                    // Generic 
                    .jumbotron {
                        background: var(--secondary);
                        color: var(--primary-text);
                    }

                    .legenda-bottom{

                        .blu{
                            color:$blue;
                        }
                        .rosso{
                            color:$rosso;
                        }
                        .arancione{
                            color:$arancione;
                        }
                        .giallo{
                            color:$yellow;
                        }
                        .verde-vin{
                            color:$verde-vin;
                        }
                        .verde-pag {
                            color: $verde-pag;
                        }
                        
                    }

                    //.opzioni-ricerca {
                        .opzioni-container {
                            &::before {
                                background:  var(--primary-box);
                            }
                        }
                   // }

                    // Movimenti
                    .tab-ric-mov {
                        color: var(--primary-text);
                    }

                    .risultati-container {
                        //border-top: 5xp solid var(--primary-box);
                        .title-page {
                            color: var(--primary-text);
                        }
                        .well {
                            background: var(--secondary);
                            border-color: var(--secondary);
                            color: var(--primary-text);
                        }
                    }


                    // Dati personali
                    .account-personal-data-container {
                        .data-container {
                            ul.nav.nav-tabs {

                                li {
                                    a {

                                    }
                                    &:hover,
                                    &:active,
                                    &.active {
                                        a {
                                            background: var(--trasp);
                                            background-color: var(--trasp);
                                            border-color: var(--primary);
                                            color: var(--primary);
                                        }
                                    }
                                }
                            }


                        }
                    }

                    // Trasferimenti 
                    .trasferimenti-container {
                        .option-container-saldo {
                            .jumbotron {
                                background: var(--secondary);
                            }
                        }
                    }

                    // Pagamenti

                    #div-payment-iframe{
                        background: var(--light);
                    }

                    // Agenz - Lista Utenti
                    .lista-utenti {
                        .opzioni-ricerca {
                            .button-container-top {
                                .btn {
                                    background: var(--trasp);
                                    border: 1px solid var(--primary);
                                    color: var(--primary-text);
                                }
                            }
                        }
                    }

                    // Giroconto
                    .giroconto {

                    }

                    // .bonus,
                    .cambia-pwd {
                        background:var(--tertiary-box);
                    }

                    .bonus{
                        .bonus-container{
                            .panel{
                                background: var(--secondary);
                                color: var(--primary-text);
                                .panel-heading{
                                    background:  var(--fourth);
                                    h4.panel-title.navbar{
                                        color: var(--primary-text);
                                        a{
                                            display: block;
                                            span{
                                                &.pull-right{
                                                    i{
                                                        color: var(--primary);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .panel-collapse{
                                    a{
                                        color: var(--primary);
                                    }
                                }
                            }
                        }
                    }


                    // Resoconti page 
                    .resoconti-giochi {
                        .risultati-container {
                            .panel {
                                background : var(--tertiary-box);
                                .panel-body {
                                    table{
                                        tr{
                                            &:hover{
                                                //background: rgba(light, 0.12);
                                                background: var(--semi-light-12);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                    #div-payments-list {
                        #payments-list {

                            .card {
                                .cont {
                                    background:  var(--primary-box);

                                }
                            }
                        }
                    }

                    // Antieffrazione
                    .gestione-antieffrazione {
                        .antieff-gest {
                            ul.list-inline {
                                background:  var(--primary-box);
                                #attivatoil {
                                    color: var(--primary);
                                }
                            }
                        }

                    
                    }


                    // Report
                    .report {
                        .page-title {
                        }
                        .report-container {
                            .risultati-container {
                                // margin-top: 1rem;
                                // background : var(--secondary);
                                
                                .title-page {
                                }
                                .totali-header {
                                    .title {
                                        color: var(--primary);
                                        border-top-color: var(--semi-light);
                                        border-bottom-color: var(--semi-light);
                                    }
                                    .row {
                                        .col-sm-2{
                                            .box-container{
                                                span{
                                                    color: var(--primary);
                                                }    
                                            }
                                        }
                                    }
                                }

                                .report-page{
                                    .panel{
                                        background: var(--trasp);
                                        .panel-header{
                                            background:  var(--primary-box);
                                            border-bottom-color: var(--tertiary-box);
                                            &:after {
                                                color: var(--primary-text)
                                            }
                                            .title{
                                                span.badge{
                                                   
                                                }
                                                span{
                                                    span{
                                                        color: var(--primary);
                                                    }
                                                }
                                            }
                                        }
                                        .panel-body{
                                            table{
                                                tbody{
                                                    tr{
                                                        &:hover{
                                                            //background: rgba(light, 0.12);
                                                            background: var(--semi-light-12);
                                                            td{
                                                                &.agenzia{
                                                                    color: var(--primary);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                tfoot{
                                                    ///background: dark ( var(--fourth, 5%);
                                                    background: var(--semi-dark-5);
                                                    tr{
                                                        &:hover{
                                                            background:  var(--semi-dark-3);
                                                        }
                                                    }
                                                }
                                            }
                                        }
    
                                    }
                                    
                                    .panel-master{
                                        span.badge{
                                            background: #40993d;
                                        }                                    
                                    }
                                    .panel-agente{
                                        span.badge{
                                            background: #2d2dac;
                                        }
                                    }  
                                    .panel-subagente{
                                        span.badge{
                                            background: #ff4800;
                                        }
                                    }
                                }
                                .right-box {
                                    P {
                                        span{
                                            color: var(--primary);
                                        }
                                    }
                                }
                            }
                        }

                    }


                    // Messaggi 
                    .messaggi {
                        .page-title {
                            .fa-hover.col-md-3 {
                                a {
                                    color:  var(--custom-red);
                                    &:hover {
                                        color: var(--primary);
                                    }
                                }
                            }
                            .non-read{
                                color: var(--primary-text);
                            }
                        }

                        .messaggi-container {

                            #divnmess{
                                color: var(--primary-text);
                            }

                            .panel {
                                background:  var(--primary-box);
                                .panel-heading {
                                    background:  var(--primary-box);
                                    border-color: var(--trasp);
                                    h4{
                                        color: var(--primary-text);
                                    }
                                    .fa-hover {
                                        a {
                                            i {
                                                color: var(--primary);
                                            }
                                            
                                            &:hover {
                                                color: var(--primary);
                                            }
                                        }
                                    }
                                    .navbar {
                                        a {
                                            color: var(--primary-text);
                                            &:hover {
                                                color: var(--primary);
                                            }
                                        }
                                        span {
                                            color: var(--primary-text);
                                        }
                                    }

                                }
                                .panel-collapse {
                                    background: var(--secondary);    
                                    .panel-body{
                                        p{
                                            color: var(--primary-text);
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                }

                .lista-scommesse,
                .account-personal-data-container {

                    .myaccount-cont {
                        .row:last-child {
                            .col-sm-6 {
                                > .title {
                                    //display: none;
                                    color: var(--primary-text);
                                }
                                ul {
                                    li {
                                        background: var(--secondary);
                                        a {
                                            span.icon {
                                                color: var(--primary);
                                            }
                                            &:hover {
                                                color: var(--primary);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }

            }
            
        }
        
    }

    

    &.recupera-pwd{
        section#main{
            color: var(--primary-text);            
            background: var(--tertiary-box);
            .container-fluid.recupera-pwd{
                .page-title{
                    @include heading-small;
                    background: var(--trasp);
                    h4{
                        color: var(--primary);
                    }
                }              
            }
            .dati-container{
                .rec-password{
                    background: var(--secondary);

                }

            }
        }
    }
    
}


body.payments-cc{
    background: var(--tertiary-box);
    .cont{
        .help-block-error{
            color: #a94442;
            background-color: #f2dede;
            border-color: #a94442;
        }
        .form-group.col-sm-6,
        .form-group.col-sm-12{
            label{
                color: var(--light);
             }
            // .form-control{
            //     background: rgba(0,0,0,0.6);
            //     border-color: rgba(0,0,0,0.6);
            //     color: var(--light);;
            // }
            // .form-control[disabled]{
            //     background-color: rgba(0, 0, 0, 0.6);
            // }
        }
        .btn-cont{
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 50px;
        }
    }
}

// calendar

.ui-datepicker{
    background: var(--tertiary-box);
    border-color: var(--semi-light);
    .ui-widget-header{
        background:  var(--fourth);
        a{
            &.ui-state-hover{
                background: var(--primary);
            }
        }
        .ui-datepicker-title{
            color: var(--tertiary-text);
        }
    }
    table{
        color: var(--primary-text);
        tr{
            td{
                a{
                    &.ui-state-default{
                        background: var(--semi-light);
                        color: var(--primary-text);
                        border-color: var(--semi-light);
                    }
                    &.ui-state-active{
                        background: var(--primary-btn);
                        color: var(--primary-btn-color);
                    }
                }
            }
        }
    }
}


